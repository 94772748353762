import { useState, useCallback } from 'react';

const useRefresh = () => {
	const [key, setKey] = useState(0);

	const refresh = useCallback(() => {
		setKey(prevKey => prevKey + 1);
	}, []);

	return [key, refresh];
};

export default useRefresh;
