import React from 'react';
import { useBlog } from '../tools/blog-context.jsx';
import BlogPost from '../components/blog-post.jsx';
import BoxReveal from '../components/text-reveal.jsx';
import TextReveal from '../components/text-reveal.jsx';
import { FaChevronRight } from 'react-icons/fa';
const Insights = ({ locale }) => {
	const { posts, loading } = useBlog();
	const isMobile = window.innerWidth < 1024;
	if (loading) {
		return <div className='text-white'>Loading...</div>;
	}
	if (posts.length === 0) {
		return (
			<div className='max-w-screen-xl mx-auto'>
				<div id="hero__insights" className="max-w-screen-xl px-6 2xl:px-0 px-4  h-[80vh]  flex flex-col justify-center mx-auto h-[40vh]">
					{/* <TextBlock
						isTitle={true}
						isHero={false}
						title={"Insights - Under Construction"}
						text={<></>}
					/> */}
					<BoxReveal boxColor={isMobile ? "#000000" : "#f6efda"} duration={0.5}>
						<h2 className={`font-common text-[32px] lg:text-[42px] 2xl:text-[48px] lg:mb-0 mb-3 font-medium w-full tracking-tight `}>
							Insights - Under Construction
						</h2>
					</BoxReveal>
				</div>
			</div>
		)
	}
	else {
		return (
			<div className='max-w-screen-xl mx-auto'>
				<div className="text-[25px] lg:text-[35px] pb-3 poppins w-full text-center text-black font-semibold xl:text-[50px] 2xl:text-[60px]">
					{locale === 'en' ? 'Insights' : 'Inzichten'}
				</div>

				<h2 className='mt-10 mb-4 text-xl'>More insights</h2>
				<div className='w-full pb-20  grid grid-cols-1 lg:grid-cols-2 gap-3  md:px-6 items-center  2xl:px-0 px-4  '>
					{posts.map(post => (
						// <div className='flex flex-row justify-between  items-center' key={post.id}>
						// 	<h3 className='text-xl font-inter font-medium text-zinc-500'>{post.title}</h3>
						// 	<FaChevronRight className=" text-zinc-500 ml-auto" />
						// </div>
						<BlogPost
							key={post.id}
							post={post}
						/>
					))}
				</div>
			</div>
		)
	}
};

export default Insights;
