import { useNavigate } from 'react-router-dom';
import { convertTextToSlug } from '../tools/cn';
import { FaChevronRight } from 'react-icons/fa';
const BlogPost = ({ post }) => {
	const navigate = useNavigate();
	return (
		<article onClick={() => navigate(`/insights/${convertTextToSlug(post.title)}`, { state: { post } })} className="relative bg-white cursor-pointer hover:scale-[1.03] h-full transform-gpu transition-all overflow-hidden rounded shadow-lg transition hover:shadow-xl">
			<img
				alt=""
				src={post.feature_image}
				className="absolute  inset-0   h-full w-full object-cover "
			/>
			<div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64">
				<div className="p-4 flex justify-between items-center  sm:p-10">
					<h4 className="mt-0.5 text-xl lg:text-2xl font-medium leading-[160%]  text-white">{post.title}</h4>
					<FaChevronRight className="text-white ml-20  text-2xl" />
				</div>
			</div>
		</article>
	)
}

export default BlogPost;