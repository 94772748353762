import Button from "../../button";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { registerAttendee } from "../../../tools/supabase";
import { toast } from "react-toastify";

const translations = {
	nl: {
		formHeader: "Vul het onderstaande formulier in om uw stichting aan te melden voor een gratis website.",
		messageLabel: "Vertel ons over uw organisatie en hoe een nieuwe website uw doelen zou kunnen ondersteunen.",
		companyName: "Organisatie",
		contactEmail: "Contact email",
		contactPhone: "Contact Telefoon",
		applyButton: "Meld Uw Stichting Aan"
	},
	en: {
		formHeader: "Fill out the form below to apply for a free website for your non-profit.",
		messageLabel: "Tell us about your organization and how a new website could support your goals.",
		companyName: "Organisation",
		contactEmail: "Contact email",
		contactPhone: "Contact phone",
		applyButton: "APPLY NOW"
	}
};
const EmptyAttendee = {
	name: "",
	email: "",
	tel: "",
	message: ""
}

const RegisterFreeSite = ({ locale }) => {

	const t = translations[locale];
	const [attendee, setAttendee] = useState(EmptyAttendee);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const submit = async () => {
		setIsLoading(true);
		const response = await registerAttendee(attendee, locale);
		setTimeout(() => {
			if (response.isSuccess) toast.success(response.message);
			else toast.error(response.message);
			setAttendee(EmptyAttendee)
			setIsLoading(false);
			setIsConfirmed(false);
		}, 2000)
	}

	return (
		<div className="register-free-site max-w-screen-xl 2xl:px-0 px-4 mx-auto w-full  ">
			<h4 className="text-[24px] lg:text-[28px] leading-[160%] font-semibold mt-10 mb-5 ">{t.formHeader}</h4>
			<form>
				<div className="mb-5">
					<label
						htmlFor="name"
						className="text-black"
					>
						{t.companyName}
					</label>
					<input
						type="text"
						id="name"
						name="name"
						value={attendee.name}
						onChange={(e) => setAttendee({ ...attendee, name: e.target.value })}
						className={`peer block w-full bg-white border border-neutral-300 bg-transparent p-4  px-5 text-base/6 text-neutral-950 ring-4 ring-transparent transition focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5 group-first:rounded-t rounded `}
					/>
				</div>
				<div className="mb-5">
					<label
						htmlFor="email"
						className="text-black"
					>
						{t.contactEmail}
					</label>
					<input
						type="email"
						id="email"
						name="email"
						value={attendee.email}
						onChange={(e) => setAttendee({ ...attendee, email: e.target.value })}
						className={`peer block w-full bg-white border border-neutral-300 bg-transparent p-4  px-5 text-base/6 text-neutral-950 ring-4 ring-transparent transition focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5 group-first:rounded-t rounded `}
					/>
				</div>
				<div className="mb-5">
					<label
						htmlFor="tel"
						className="text-black"
					>
						{t.contactPhone}
					</label>
					<input
						type="text"
						id="tel"
						name="tel"
						value={attendee.tel}
						onChange={(e) => setAttendee({ ...attendee, tel: e.target.value })}
						className={`peer block w-full bg-white border border-neutral-300 bg-transparent p-4  px-5 text-base/6 text-neutral-950 ring-4 ring-transparent transition focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5 group-first:rounded-t rounded `}
					/>
				</div>
				<div>
					<label
						htmlFor="message"
						className="text-black"
					>
						{t.messageLabel}
					</label>
					<textarea
						type="text"
						id="message"
						name="message"
						value={attendee.message}
						onChange={(e) => setAttendee({ ...attendee, message: e.target.value })}
						placeholder=""
						rows={3}
						className={`peer block w-full bg-white border border-neutral-300 bg-transparent p-4 text-base/6 px-5  text-neutral-950 ring-4 ring-transparent transition focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5 group-first:rounded-t rounded `}
					/>
				</div>
				<div className="flex flex-row gap-3 items-center my-5 ">
					<input checked={isConfirmed} onChange={e => setIsConfirmed(!isConfirmed)} type="checkbox" id="terms" name="terms" />
					<label htmlFor="terms" className="text-black">
						{locale === "nl" ? "Ik ga akkoord met de voorwaarden " : "I agree to the terms and conditions"}
					</label>
				</div>
			</form>
			<div className="grid grid-cols-4 w-full mt-5 overflow-hidden">
				<Button type="button" disabled={!isLoading && isConfirmed && attendee.email && attendee.name && attendee.tel && attendee.message ? false : true} onClick={() => submit()} size="lg" variant="primary" hasborder={true}>{t.applyButton}  {isLoading ? <CgSpinner className="animate-spin text-[var(--primary)]" size="20" /> : <IoArrowForwardCircleSharp size="20" />}</Button>
			</div>
		</div>
	)
}

export default RegisterFreeSite;