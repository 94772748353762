import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Logo from "./assets/branding/brand-white-gold.png";
import LogoB from "./assets/branding/brand-blackgold.png";
import Home from "./pages/home.jsx";
import Contact from "./pages/contact.jsx";
import Cases from "./pages/cases.jsx";
import Service from "./pages/services.jsx";
import Privacy from "./pages/privacy.jsx";
import Insights from "./pages/insights.jsx";
import { RiLinkedinFill } from "react-icons/ri";
import { VscClose, VscMenu } from "react-icons/vsc";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LocomotiveScroll from 'locomotive-scroll';
import { BlogProvider } from "./tools/blog-context.jsx";
import InsightPost from "./pages/insights-post.jsx";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MenuContent from "./components/menu-content.jsx";
import { IoMdCalendar } from "react-icons/io";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { PopupWidget } from "react-calendly";
import useRefresh from './tools/useRefresh';
import Button from "./components/button.jsx";
import Collaborating from "./pages/collaborating.jsx";
import Globe from "./components/v2/globe/globe.jsx";
import { AnimatePresence, motion } from "framer-motion";
import PWA from "./pages/pwa.jsx";
import CaseDetails from "./pages/caseDetails.jsx";

gsap.registerPlugin(ScrollTrigger);

/**
 * Represents the layout component of the website.
 *
 * @component
 * @returns {JSX.Element} The layout component.
 */
const Layout = () => {
	const [isHeaderOpen, setIsHeaderOpen] = useState(false);
	const [locale, setLocale] = useState("en");
	const [key, refresh] = useRefresh();

	useEffect(() => {
		window.scrollTo(0, 0);
		const locomotiveScroll = new LocomotiveScroll();
		return () => {
			locomotiveScroll.destroy();
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		refresh();
		setIsHeaderOpen(false);
	}, [locale, refresh]);

	return (
		<main id="main" className="max-w-screen overflow-x-hidden ">
			<BlogProvider>
				<BrowserRouter>
					<Header locale={locale} setIsHeaderOpen={setIsHeaderOpen} setLocale={setLocale} isHeaderOpen={isHeaderOpen} />
					<Content key={key} locale={locale} setLocale={setLocale} setIsHeaderOpen={setIsHeaderOpen} />
					<Footer locale={locale} setLocale={setLocale} />
					<div id="calendly-wrapper" className="hidden">
						<PopupWidget
							id="popup-widget"
							url="https://calendly.com/olivierbrinkman/30min"
							rootElement={document.getElementById("root")}
							text="Plan a call"
							color="black">
							Plan a call
						</PopupWidget>
					</div>
				</BrowserRouter>
			</BlogProvider>
		</main>
	);
};

/**
 * Header component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setIsHeaderOpen - Function to set the state of the header open/close.
 * @param {boolean} props.isHeaderOpen - Boolean indicating whether the header is open or closed.
 * @param {string} props.locale - The current locale.
 * @param {Function} props.setLocale - Function to set the current locale.
 * @returns {JSX.Element} The rendered Header component.
 */
const Header = ({ setIsHeaderOpen, isHeaderOpen, locale, setLocale }) => {
	const navigate = useNavigate();
	const logoRef = useRef(null);
	const toggleRef = useRef(null);
	const overlayRef = useRef(null);
	const { pathname } = useLocation();

	// Debounce function to optimize scroll event
	const debounce = (func, wait) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(this, args), wait);
		};
	};

	useEffect(() => {
		let lastScrollTop = 0;
		const handleScroll = debounce(() => {
			if (!isHeaderOpen) {
				const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
				const scrollHeight = document.documentElement.clientHeight;

				if (Math.abs(scrollTop - lastScrollTop) > scrollHeight * 0.1) {
					const direction = scrollTop > lastScrollTop ? "-5px" : "0px";
					const logoY = scrollTop > lastScrollTop ? -100 : 0;

					gsap.to(toggleRef.current, { opacity: 1, duration: 0.25, y: direction, autoKill: true });
					gsap.to(logoRef.current, { opacity: 1, duration: 0.25, y: logoY, autoKill: true });

					lastScrollTop = Math.max(scrollTop, 0);
				}
			}
		}, 100);

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [isHeaderOpen]);

	useEffect(() => {
		document.body.style.overflow = isHeaderOpen ? "hidden" : "auto";
	}, [isHeaderOpen]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setIsHeaderOpen(false);
	}, [pathname, setIsHeaderOpen, locale]);

	const handleToggleClick = () => setIsHeaderOpen((prev) => !prev);
	return (
		<div className="relative z-[999]">
			{/* Header controls */}
			<div className="w-full fixed p-5 left-0 top-0">
				<div
					ref={toggleRef}
					className="text-zinc-500 hover:text-black hover:border-black border-zinc-300 border absolute lg:left-4 lg:top-[15px] left-4 top-4 cursor-pointer transition-all w-fit rounded-full p-3 lg:p-4 bg-white backdrop-blur"
					onClick={handleToggleClick}
				>
					<VscMenu className="lg:text-[30px] text-black text-[20px]" />
				</div>

				<img
					ref={logoRef}
					src={LogoB}
					alt="logo"
					className="lg:mt-5 mt-2 mx-auto lg:translate-x-0 translate-x-[0px] hover:cursor-pointer w-[150px] lg:w-[200px]"
					onClick={() => navigate("/")}
				/>

				<div className="flex fixed justify-end right-4 top-7 lg:right-4 lg:top-10 gap-0 items-center text-base gap-2 lg:text-lg font-medium text-black">
					<div
						onClick={() => setLocale("en")}
						className={locale === "en" ? "text-black flex items-center gap-2 rounded" : "text-black grayscale flex items-center gap-2 cursor-pointer hover:bg-zinc-100 rounded"}
					>
						ENG
					</div>
					<div
						onClick={() => setLocale("nl")}
						className={locale === "nl" ? "text-black flex items-center gap-2 rounded" : "text-black grayscale flex items-center gap-2 cursor-pointer hover:bg-zinc-100 rounded"}
					>
						NL
					</div>
				</div>
			</div>

			{/* Animated Menu */}
			<AnimatePresence>
				{isHeaderOpen && (
					<motion.div
						initial={{ y: "-100%", opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: "-100%", opacity: 0 }}
						transition={{ duration: 0.5, ease: "easeInOut" }}
						className="fixed transform-gpu w-full h-full bg-white bg-dot-black/[0.2] text-white rounded-3xl z-[9999] flex flex-col items-center justify-center text-3xl"
					>
						<div
							className="text-zinc-500 hover:text-black hover:border-black border-zinc-300 border z-[9999] absolute lg:left-4 lg:top-[15px] left-4 top-4 cursor-pointer transition-all w-fit rounded-full p-3 lg:p-4 bg-white backdrop-blur"
							onClick={handleToggleClick}
						>
							<VscClose className="lg:text-[30px] text-black text-[20px]" />
						</div>
						<MenuContent locale={locale} setLocale={setLocale} />
					</motion.div>
				)}
			</AnimatePresence>

			{/* Overlay (optional) */}
			<div ref={overlayRef} className="fixed inset-0 bg-white opacity-0 z-[9998] pointer-events-none"></div>
		</div>
	);
};
/**
 * Renders the main content of the website.
 *
 * @param {Object} props - The component props.
 * @param {string} props.locale - The locale of the website.
 * @returns {JSX.Element} The rendered component.
 */
const Content = ({ locale }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div id="content" className="relative z-[201] md:rounded-t-none overflow-hidden md:overflow-visible pt-48  lg:pt-48">
			<Routes>
				<Route path="/" element={<Home locale={locale} />} />
				<Route path="/privacy" element={<Privacy locale={locale} />} />
				<Route path="/projects" element={<Cases locale={locale} />} />
				<Route path="/projects/:projectSlug" element={<CaseDetails locale={locale} />} />
				<Route path="/services" element={<Service locale={locale} />} />
				<Route path="/pwa" element={<PWA locale={locale} />} />
				<Route path="/contact" element={<Contact locale={locale} />} />
				<Route path="/collaborating" element={<Collaborating locale={locale} />} />
				<Route path="/insights" element={<Insights locale={locale} />} />
				<Route path="/insights/:postSlug" element={<InsightPost locale={locale} />} />
				<Route path="*" element={<div className="h-screen w-[-webkit-fill-available] flex justify-center items-center text-zinc-500 font-normal italic text-base">404 - PAGE NOT FOUND...</div>} />
			</Routes>
			<ToastContainer
				position="top-right"
				color="white"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</div>
	);
};

/**
 * Footer component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.locale - The current locale.
 * @param {function} props.setLocale - The function to set the locale.
 * @returns {JSX.Element} The rendered Footer component.
 */
const Footer = ({ locale, setLocale }) => {
	const navigate = useNavigate();
	return (
		<footer id="footer" className="md:pt-0  w-full bg-black  bg-dot-white/[0.2]  lg:pt-14 px-5 md:px-20 flex flex-col">
			<div className="w-full flex justify-center items-center">
				<Globe className="mx-auto my-20  " />
			</div>
			<div className="gap-4 flex flex-col w-full mx-auto border-neutral-700 pb-4">
				<img onClick={() => navigate("/contact")} src={Logo} alt="logo" className="mx-auto hover:cursor-pointer mb-4 lg:mb-10" width="200" />
				<div className=" lg:max-w-xl mx-auto grid-cols-4 lg:flex flex-col lg:flex-row  w-full lg:px-0 px-4 gap-2 z-[1000] lg:gap-10 w-full">
					<div className="w-full lg:mb-0 mb-2">
						<Button type="button" disabled={false} onClick={() => document.querySelector(".calendly-badge-widget")?.click()} size="lg" variant="primary" hasborder={true}>{locale === "nl" ? "MAAK AFSPRAAK" : "BOOK A CALL"}<IoMdCalendar size="22" /></Button>
					</div>
					<div className="w-full">
						<Button type="button" disabled={false} onClick={() => navigate("/contact")} size="lg" variant="primary" hasborder={true}>CONTACT <IoArrowForwardCircleSharp size="22" /></Button>
					</div>
				</div>
				<div className="w-full text-center border-zinc-500/20 pt-10  border-t mx-auto w-full max-w-[500px] justify-between flex flex-row items-center border-zinc-600/30 px-6 flex items-center gap-12 text-zinc-500">
					<Link className="text-lg text-left md:text-lg w-[-webkit-fill-available] font-normal hover:text-white" to="/privacy">Privacy Policy</Link>
					<a href="https://www.linkedin.com/company/walhallah" rel="noreferrer" target="_blank" className="w-full text-base hover:text-white md:text-lg ml-auto font-semibold">
						<RiLinkedinFill size="22" className="ml-auto" />
					</a>
				</div>
			</div>
			<div className="flex border-zinc-500/20 pt-10   w-[-webkit-fill-available] justify-center mb-4 gap-3 items-center text-base md:text-lg font-medium text-zinc-500">
				<div onClick={() => setLocale("en")} className={locale === "en" ? "text-white" : "cursor-pointer"}>ENG</div>
				<div onClick={() => setLocale("nl")} className={locale === "nl" ? "text-white" : "cursor-pointer"}>NL</div>
			</div>
		</footer>
	);
};

console.log('%c Built by Walhallah Agency® ', 'background: #d4af37; color: black; fontWeight:bold; fontSize: 30px; padding: 20px;');
export default Layout;
