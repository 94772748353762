import React, { useId, useRef, useState } from 'react';
import TextBlock from "../components/text-block";
import Button from '../components/button';
import emailjs from '@emailjs/browser';
import Modal from '../components/modal';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import TextReveal from '../components/v2/text-reveal/text-reveal';

const translations = {
	nl: {
		contact: "Contact",
		heroTitle: "Laat van je horen",
		heroText: "En wij nemen zo snel als mogelijk contact met je op!",
		workInquiries: "Werkvragen",
		nameLabel: "Naam",
		emailLabel: "Email",
		companyLabel: "Bedrijf",
		phoneLabel: "Telefoon",
		messageLabel: "Bericht",
		sendButton: "Verstuur bericht",
		sendingButton: "Versturen...",
		successMessage: (
			<>
				<IoMdCheckmark size="46" className='text-[var(--primary)] bg-black rounded-full p-3 ' />
				Je bericht is ontvangen. We nemen zo snel als mogelijk contact met je op.
			</>
		),
		errorMessage: (
			<>
				<IoMdClose size="46" className='text-red-400 bg-black rounded-full p-3 ' />
				Oeps, dit ging niet goed. Probeer het later nogmaals.
			</>
		)
	},
	en: {
		contact: "Contact",
		heroTitle: "Get in Touch",
		heroText: "And we will get back to you as soon as possible!",
		workInquiries: "Work Inquiries",
		nameLabel: "Name",
		emailLabel: "Email",
		companyLabel: "Company",
		phoneLabel: "Phone",
		messageLabel: "Message",
		sendButton: "Send Message",
		sendingButton: "Sending...",
		successMessage: (
			<>
				<IoMdCheckmark size="46" className='text-[var(--primary)] bg-black rounded-full p-3 ' />
				Your message has been received. We will get back to you as soon as possible.
			</>
		),
		errorMessage: (
			<>
				<IoMdClose size="46" className='text-red-400 bg-black rounded-full p-3 ' />
				Oops, something went wrong. Please try again later.
			</>
		)
	}
};

const Contact = ({ locale }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [message, setMessage] = useState('');
	const form = useRef();
	const t = translations[locale];

	const sendEmail = (e) => {
		e.preventDefault();
		setIsSending(true);
		emailjs
			.sendForm('service_barfpz8', 'template_nm58c9c', form.current, {
				publicKey: process.env.REACT_APP_EMAILJS_KEY,
			})
			.then(
				() => {
					setMessage(t.successMessage);
					setIsSending(false);
					setIsModalOpen(true);
					setTimeout(() => {
						setIsModalOpen(false);
					}, 5000);
				},
				(error) => {
					setMessage(t.errorMessage);
					setIsSending(false);
				},
			);
	};

	return (
		<section id="page-Contact" className='pb-20'>
			<div id="hero__contact" className="isolate max-w-screen-xl mx-auto h-full flex flex-col px-4 2xl:px-0 justify-center">
				<div className="2xl:w-2/3 w-full">
					<TextReveal>
						<span className="text-[35px] poppins lg:text-[45px] pb-3 text-black font-semibold xl:text-[60px] 2xl:text-[70px]">{t.heroTitle}</span>
						<span className="text-[16px] lg:text-xl lg:w-2/3 font-normal leading-[160%] md:leading-[160%]">{t.heroText}</span>
					</TextReveal>
				</div>
			</div>
			<div className='w-full text-lg font-semibold py-2 px-4 2xl:px-0 max-w-screen-xl mx-auto text-left'>
				contact@walhallah.com
			</div>
			<div className="isolate mb-0  z-[100] rounded max-w-screen-xl mx-auto w-[-webkit-fill-available] px-4 2xl:px-0 flex flex-col h-fit justify-center items-start">
				<div className="isolate mt-6 backdrop-blur-xl -space-y-px w-full">
					<form className="rounded" ref={form} onSubmit={sendEmail}>
						<TextInput first={true} label={t.nameLabel} name="name" autoComplete="name" />
						<TextInput
							label={t.emailLabel}
							type="email"
							name="email"
							autoComplete="email"
						/>
						<TextInput
							label={t.companyLabel}
							name="company"
							autoComplete="organization"
						/>
						<TextInput label={t.phoneLabel} type="tel" name="phone" autoComplete="tel" />
						<TextInput last={true} label={t.messageLabel} name="message" className="mb-10 border" />
						<Button type="submit" disabled={isSending} size="lg" variant="primary" hasborder={true}>
							{isSending ? t.sendingButton : t.sendButton}
							<IoArrowForwardCircleSharp size="22" />
						</Button>
					</form>
				</div>
				<Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} content={message} />
			</div>
		</section>
	);
};

export function TextInput({ label, first, last, ...props }) {
	let id = useId()
	return (
		<div className={`group relative z-0 transition-all focus-within:z-10 mb-4 `}>
			<input
				type="text"
				id={id}
				{...props}
				placeholder=" "
				className={`peer block w-full border border-neutral-300 bg-transparent px-6 pb-3 pt-10 text-base/6 text-neutral-950 ring-4 ring-transparent transition focus:border-neutral-950 focus:outline-none focus:ring-neutral-950/5 group-first:rounded-t rounded `}
			/>
			<label
				htmlFor={id}
				className="pointer-events-none absolute left-6 top-1/2 -mt-3 origin-left text-base/6 text-neutral-500 transition-all duration-200 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:font-semibold peer-focus:text-neutral-950 peer-[:not(:placeholder-shown)]:-translate-y-4 peer-[:not(:placeholder-shown)]:scale-75 peer-[:not(:placeholder-shown)]:font-semibold peer-[:not(:placeholder-shown)]:text-neutral-950"
			>
				{label}
			</label>
		</div>
	)
}

export default Contact;
