import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdCalendar } from "react-icons/io";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import Button from "./button";
import AOS from "aos";
import { useEffect } from "react";

/**
 * MenuContent component displays the menu content for the website.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setLocale - The function to set the locale.
 * @param {string} props.locale - The current locale.
 * @returns {JSX.Element} The rendered MenuContent component.
 */
const MenuContent = ({ setLocale, locale }) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const pages = [
		{ title: "Home", link: "/" },
		{ title: "Projects", link: "/projects" },
		{ title: "Services", link: "/services" },
		{ title: "Insights", link: "/insights" },
		{ title: "Contact", link: "/contact" }
	];

	useEffect(() => {
		AOS.init({
			duration: 500,
			once: true,
		});
	}, []);

	return (
		<div className="flex lg:relative max-w-screen-xl  flex-col justify-between  lg:h-full lg:flex-col lg:py-20  justify-between text-center font-common font-medium  w-full text-2xl md:text-3xl lg:text-4xl xl:text-5xl gap-10 text-zinc-500 ">
			<div className="flex  fixed justify-end  right-4 top-6  lg:right-4 lg:top-10 gap-3 items-center text-base lg:text-xl font-medium text-black">
				<div onClick={() => setLocale("en")} className={locale === "en" ? "text-black flex items-center gap-2 p-2 bg-zinc-100  rounded " : "text-black  p-2 gayscale flex  items-center gap-2 cursor-pointer  hover:bg-zinc-100 rounded"}>
					{/* <img src={UK} width="28" alt="uk-flag" /> */}
					ENG
				</div>
				<div onClick={() => setLocale("nl")} className={locale === "nl" ? "text-black flex items-center gap-2 p-2  bg-zinc-100  rounded  " : "text-black  p-2 gayscale flex  items-center gap-2 cursor-pointer  hover:bg-zinc-100 rounded"}>
					{/* <img src={NL} width="28" alt="nl-flag" className="rounded-lg" /> */}
					NL
				</div>
			</div>
			<div className="w-full flex flex-col lg:h-full lg:justify-center justify-start items-center text-2xl lg:text-4xl lg:flex-row gap-6 mb-14 mt-20 lg:mt-20 lg:gap-20">
				{pages.map((page, index) => (
					<div data-aos="fade-down" data-aos-delay={`${index + 1}00`} className={` transition-all lg:w-full pb-2  ${pathname === page.link ? " border-zinc-500 text-black   " : " border-white text-zinc-700  hover:border-black"} cursor-pointer`}>
						<Link key={index} to={page.link} className={`  font-medium  `}>{page.title}</Link>
					</div>
				))}
			</div>
			<div id="menu-actions" data-aos="fade-up" data-aos-delay="500" className=" lg:max-w-xl mx-auto grid-cols-4 lg:flex flex-col lg:flex-row  w-full lg:px-0 px-4 gap-2 z-[1000] lg:gap-10 w-full">
				<div className="w-full lg:mb-0 mb-2">
					<Button type="button" disabled={false} onClick={() => document.querySelector(".calendly-badge-widget")?.click()} size="lg" variant="primary" hasborder={true}>{locale === "nl" ? "MAAK AFSPRAAK" : "BOOK A CALL"}<IoMdCalendar size="22" /></Button>
				</div>
				<div className="w-full">
					<Button type="button" disabled={false} onClick={() => navigate("/contact")} size="lg" variant="primary" hasborder={true}>CONTACT <IoArrowForwardCircleSharp size="22" /></Button>
				</div>
			</div>
		</div>
	)
}

export default MenuContent;