const Button = ({ disabled, onClick, size, children, variant, hasborder, type, props }) => {
	if (disabled === null) disabled = false;
	if (variant === 'primary') {
		return (
			<button type={type} disabled={disabled} {...props} onClick={onClick} className={` group disabled:text-zinc-500 disabled:bg-zinc-800 disabled:hover:cursor-not-allowed  cursor-pointer rounded py-3 ${size === "xl" && "lg:py-6"} lg:py-4 hover:pr-4 pl-4 pr-7 lg:col-span-1 rounded col-span-4 z-10 w-full  border-zinc-500/30 hover:bg-white bg-black transition-all text-white border hover:border-black hover:text-black justify-between  text-base lg:text-xl  mx-0 xl:mx-0 font-semibold`}>
				<span className="lg:text-lg  text-base w-full   flex flex-row  font-common font-normal  gap-3 items-center justify-between">{children}</span>
			</button>
		)
	}
	if (variant === 'secondary') {
		return (
			<button type={type} disabled={disabled} {...props} onClick={onClick} className={` group   cursor-pointer ${size === "xl" && "lg:py-6"} py-3 lg:py-4 disabled:text-zinc-500 disabled:bg-zinc-800 disabled:hover:cursor-not-allowed hover:pr-4 pl-4 pr-7 lg:col-span-1 col-span-4 rounded  z-10 w-full border  bg-white hover:bg-black hover:text-white  transition-all text-black justify-between border text-base lg:text-xl  mx-0 xl:mx-0 font-semibold`}>
				<span className="lg:text-lg  text-base w-full   flex flex-row  font-common font-normal  gap-3 items-center justify-between">{children}</span>
			</button>
		)
	}
};

export default Button