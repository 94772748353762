import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import RecentBlogs from "../components/recent-blogs";
import SubscriptionForm from "../components/subscribe";
import { useBlog } from "../tools/blog-context";
import { GridPattern } from "../components/grid-pattern";
import Button from "../components/button";
import RecentProjects from "../components/recent-projects";
import TextReveal from "../components/v2/text-reveal/text-reveal";
import AOS from "aos";

gsap.registerPlugin(ScrollTrigger);

const translations = {
	nl: {
		heroTitle: "Digital Ingenuity",
		heroText: "We bouwen digitale oplossingen die het verschil maken. Onze focus ligt op het creëren van digitale oplossingen door teamwork en het toepassen van de nieuwste technologieën.",
		contactButton: "CONTACT",
		collaboratingTitle: "Samenwerken voor een beter morgen",
		collaboratingSubtitle: "Gratis website voor uw stichting",
		collaboratingButton: "SCHRIJF JE IN",
		servicesTag: "Diensten",
		servicesTitle: "Wat we doen",
		servicesText: "Wij gebruiken een breed scala aan technologieën en tools om u te kunnen helpen, van het bouwen van apps, web-ontwikkeling tot het ontwerpen van UX/UI-interfaces tot smart contracts.",
		servicesButton: "DIENSTEN",
		visionTag: "Visie",
		visionTitle: "Waarin we geloven",
		visionText1: "We geloven in het leveren van innovatieve oplossingen die niet alleen voldoen aan de huidige behoeften van onze klanten, maar ook anticiperen op toekomstige ontwikkelingen in de digitale wereld. Door vooruit te kijken naar de verwachte ontwikkelingen binnen websites, apps, AI en blockchain, streven we ernaar om innovatieve oplossingen te leveren die niet alleen aan de behoeften van vandaag voldoen, maar ook voorbereid zijn op de uitdagingen van morgen.",
		visionText2: "We werken zowel samen als dynamisch, waarbij we creativiteit combineren met strategisch denken. Ons proces omvat zowel verkenningen van de behoeften van de klant, het verkennen van innovatieve oplossingen als goed testen om kwaliteit te garanderen. We zijn altijd verbonden met een wereldwijd expertise netwerk, waaruit we kunnen putten om cutting-edge digitale ervaringen te leveren.",
		workTag: "Werk",
		workTitle: "Projecten",
		workText: "We hebben gewerkt aan een breed scala aan projecten, van kleine websites tot complexe webapplicaties. Wij zijn toegewijd aan het leveren van hoogwaardig werk op tijd en binnen budget.",
		workButton: "PROJECTS",
		subText: "Blijf up-to-date",
		pwaTitle: "Progressive Web Apps, wat zijn dat?",
		pwaSubtitle: "Laat ons u kennis maken met de toekomst van webontwikkeling.",
		pwaButton: "LEES MEER"
	},
	en: {
		heroTitle: "Digital Ingenuity",
		heroText: "We build digital solutions that make a difference. Our focus is on creating digital solutions through teamwork and applying the latest technologies.",
		contactButton: "CONTACT",
		collaboratingTitle: "Collaborating for a better tomorrow",
		collaboratingSubtitle: "Free website for your non-profit",
		collaboratingButton: "APPLY NOW",
		servicesTag: "Services",
		servicesTitle: "What we do",
		servicesText: "We use a wide range of technologies and tools to assist you, from building apps and web development to designing UX/UI interfaces to smart contracts.",
		servicesButton: "SERVICES",
		visionTag: "Vision",
		visionTitle: "Vision",
		visionText1: "We believe in delivering innovative solutions that not only meet the current needs of our customers but also anticipate future developments in the digital world. By looking ahead to expected developments within websites, apps, AI, and blockchain, we strive to deliver innovative solutions that not only meet today's needs but are also prepared for tomorrow's challenges.",
		visionText2: "We work both collaboratively and dynamically, combining creativity with strategic thinking. Our process includes exploring customer needs, exploring innovative solutions, and thorough testing to ensure quality. We are always connected to a global network of expertise, from which we can draw to deliver cutting-edge digital experiences.",
		workTag: "Work",
		workTitle: "Projects",
		workText: "We have worked on a wide range of projects, from small websites to complex web applications. We are committed to delivering high-quality work on time and within budget.",
		workButton: "PROJECTS",
		subText: "Subscribe",
		pwaTitle: "Progressive Web Apps, what are they?",
		pwaSubtitle: "Let us introduce you to the future of web development.",
		pwaButton: "LEARN MORE"
	}
};


const Home = ({ locale }) => {
	const { subscribe } = useBlog();
	const navigate = useNavigate();
	const t = translations[locale];
	const servicesRef = useRef(null);
	const servicesTitleRef = useRef(null);

	const believeRef = useRef(null);
	const believeTitleRef = useRef(null);
	const workRef = useRef(null);
	const workTitleRef = useRef(null);
	const subscribeRef = useRef(null);
	const subscribeTitleRef = useRef(null);

	const blogRef = useRef(null);
	const blogTitleRef = useRef(null);

	useEffect(() => {
		const t1 = ScrollTrigger.create({
			trigger: servicesRef.current,
			start: "top center",
			onEnter: () => gsap.to(servicesTitleRef.current, { y: 0, duration: 0.3, opacity: 1, }),
			onLeaveBack: () => gsap.to(servicesTitleRef.current, { y: -50, duration: 0.3, opacity: 0 }),
		});

		const t2 = ScrollTrigger.create({
			trigger: believeRef.current,
			start: "top center",
			onEnter: () => gsap.to(believeTitleRef.current, { y: 0, duration: 0.3, opacity: 1, }),
			onLeaveBack: () => gsap.to(believeTitleRef.current, { y: -50, duration: 0.3, opacity: 0 }),
		});

		const t3 = ScrollTrigger.create({
			trigger: workRef.current,
			start: "top center",
			onEnter: () => gsap.to(workTitleRef.current, { y: 0, duration: 0.3, opacity: 1, }),
			onLeaveBack: () => gsap.to(workTitleRef.current, { y: -50, duration: 0.3, opacity: 0 }),
		});

		const t4 = ScrollTrigger.create({
			trigger: subscribeRef.current,
			start: "top center",
			onEnter: () => gsap.to(subscribeTitleRef.current, { y: 0, duration: 0.3, opacity: 1, }),
			onLeaveBack: () => gsap.to(subscribeTitleRef.current, { y: -50, duration: 0.3, opacity: 0 }),
		});

		const t5 = ScrollTrigger.create({
			trigger: blogRef.current,
			start: "top center",
			onEnter: () => gsap.to(blogTitleRef.current, { y: 0, duration: 0.3, opacity: 1, }),
			onLeaveBack: () => gsap.to(blogTitleRef.current, { y: -50, duration: 0.3, opacity: 0 }),
		});

		return () => {
			t1.kill();
			t2.kill();
			t3.kill();
			t4.kill();
			t5.kill();
		}
	}, []);

	useEffect(() => {
		AOS.init({ once: true });
	}, []);


	if (!t) {
		return <div>Translation not available</div>;
	}

	return (
		<section id="page-home" className=" z-[9999]">
			<GridPattern
				id="grid"
				className="mr-0 absolute opacity-1 transform-y-0 inset-x-0 top-0 z-[9999] md:z-[0] h-[1000px] w-[-webkit-fill-available] fill-[#d4af37]/[0.2] stroke-neutral-950/[0.1] [mask-image:linear-gradient(to_bottom_left,white_40%,transparent_50%)]"
				yOffset={-20}
				interactive
			/>
			<div id="hero__home" className="isolate relative max-w-screen-2xl mx-auto h-[60vh] lg:h-[60vh] z-[9999] gap-7 px-4 flex flex-col items-start lg:justify-center">
				<div className="2xl:w-3/4 w-full">
					<TextReveal>
						<span className="text-[55px] poppins lg:text-[80px] pb-3 text-black font-semibold xl:text-[90px] 2xl:text-[100px]">{t.heroTitle}</span>
						<span className="text-[16px] lg:text-xl lg:w-full font-normal leading-[160%] md:leading-[160%]">{t.heroText}</span>
					</TextReveal>
				</div>
				<div data-aos-delay="1000" data-aos="fade-up" className="grid grid-cols-4 w-full mt-7  lg:mt-3 overflow-hidden">
					<Button type="button" disabled={false} onClick={() => navigate("/contact")} size="lg" variant="primary" hasborder={true}>CONTACT<IoArrowForwardCircleSharp size="20" /></Button>
				</div>
			</div>
			<div className="w-full flex flex-col xl:flex-row lg:gap-6 items-center">
				<div id="banner" className="xl:w-1/2 w-full border-t-2  relative z-[9999]  overflow-hidden max-w-screen-xl mx-auto my-3 py-10 pb-0 lg:pb-6 pt-6  px-4">
					<div className="grid  items-center flex  grid-cols-4 w-full">
						<div className="col-span-4 lg:col-span-3">
							<div className="font-semibold mb-2 lg:text-2xl text-lg">{t.pwaTitle}</div>
							<div className="font-normal lg:mb-0 mb-4 lg:text-lg text-sm">{t.pwaSubtitle}</div>
						</div>
						<button onClick={() => navigate("/pwa")} className={`    w-full  cursor-pointer rounded py-3 h-auto  lg:py-4  hover:pr-4 pl-4 pr-7 lg:col-span-1 rounded col-span-4  w-full  border-zinc-500/30 hover:bg-white bg-black transition-all text-white border hover:border-black hover:text-black justify-between  text-base lg:text-xl  mx-0 xl:mx-0 font-semibold`}>
							<span className="lg:text-lg  text-base w-full   flex flex-row  font-commo font-normal  gap-3 items-center justify-between">{t.pwaButton}<IoArrowForwardCircleSharp size="20" /></span>
						</button>
					</div>
				</div>
				<div id="banner" className="xl:w-1/2 w-full border-t-2  relative z-[9999]  overflow-hidden max-w-screen-xl mx-auto my-3 py-10 pb-0 lg:pb-6 pt-6  px-4">
					<div className="grid  items-center flex  grid-cols-4 w-full">
						<div className="col-span-4 lg:col-span-3">
							<div className="font-semibold mb-2 lg:text-2xl text-lg">{t.collaboratingTitle}</div>
							<div className="font-normal lg:mb-0 mb-4 lg:text-lg text-sm">{t.collaboratingSubtitle}</div>
						</div>
						<button onClick={() => navigate("/collaborating")} className={`    w-full  cursor-pointer rounded py-3 h-auto  lg:py-4  hover:pr-4 pl-4 pr-7 lg:col-span-1 rounded col-span-4  w-full  border-zinc-500/30 hover:bg-white bg-black transition-all text-white border hover:border-black hover:text-black justify-between  text-base lg:text-xl  mx-0 xl:mx-0 font-semibold`}>
							<span className="lg:text-lg  text-base w-full   flex flex-row  font-commo font-normal  gap-3 items-center justify-between">{t.collaboratingButton}<IoArrowForwardCircleSharp size="20" /></span>
						</button>
					</div>
				</div>
			</div>
			<div id="services" ref={servicesRef} className="isolate h-full mx-auto mb-20 items-center gap-20 flex flex-col justify-start">
				<div className="w-full relative flex flex-col gap-2 bg-black  bg-dot-white/[0.2]  h-full py-20 xl:py-48">
					<div id="services-content" className="max-w-screen-xl mx-auto  px-4  w-full">
						<div className="overflow-hidden mb-3 ">
							<h2 ref={servicesTitleRef} className={`translate-y-[-100px] opacity-0 font-common text-[32px] lg:text-[42px] 2xl:text-[48px] text-white lg:mb-0 mb-3 font-bold w-full tracking-tight `}>
								{t.servicesTitle}
							</h2>
						</div>
						<div className="text-[18px]  lg:text-xl lg:w-1/2 w-full font-normal text-white leading-[160%] md:leading-[160%]">{t.workText}</div>

						<div className="grid grid-cols-4 w-full mt-7  lg:mt-10">
							<Button type="button" disabled={false} onClick={() => navigate("/services")} size="lg" variant="secondary" hasborder={true}>{t.servicesButton}<IoArrowForwardCircleSharp size="20" /></Button>
						</div>
					</div>
				</div>
			</div>
			<div id="believe" ref={believeRef} className="max-w-screen-xl lg:mx-auto my-20  lg:my-48  w-full  z-[9999] px-4  my-0 ">
				<div className="w-full md:rounded-xl py-10">
					<div className="overflow-hidden mb-3">
						<h2 ref={believeTitleRef} className={`translate-y-[-100px] opacity-0 font-common text-[32px] lg:text-[42px] 2xl:text-[48px] text-black lg:mb-0 mb-3 font-bold w-full tracking-tight `}>
							{t.visionTitle}
						</h2>
					</div>
					<div className='flex flex-col gap-10 w-full text-[18px] lg:text-xl  font-normal text-black'>
						<span className="leading-[160%] md:leading-[160%]">{t.visionText1}</span>
						<span className="leading-[160%] md:leading-[160%]">{t.visionText2}</span>
					</div>
				</div>
			</div>

			<div id="work" ref={workRef} className="max-w-screen-xl bg-white   flex flex-col justify-between pt-20 mx-auto mt-7 my-28 relative z-[9999] w-full   px-4 my-0">
				<div className="overflow-hidden mb-3">
					<h2 ref={workTitleRef} className={`translate-y-[-100px] opacity-0 font-common text-[32px] lg:text-[42px] 2xl:text-[48px] text-black lg:mb-0 mb-3 font-bold w-full tracking-tight `}>
						{t.workTitle}
					</h2>
				</div>
				<div className="text-[18px] lg:text-xl lg:w-1/2 w-full font-normal text-black leading-[160%] md:leading-[160%]">{t.workText}</div>

				<div className="relative lg:mt-14 mt-5 w-full h-[25em] lg:h-[30em] 2xl:h-[35em] mb-20 overflow-visible">
					<RecentProjects locale={locale} />
				</div>
			</div>
			<div id="subscribe" ref={subscribeRef} className="w-full  bg-black px-4 py-24 lg:py-32 lg:mt-32 lg:mb-20  relative z-[9999] px-4 lg:gap-5 gap-0 text-white flex flex-col text-center">
				<div className="max-w-screen-xl lg:mx-auto w-full">

					<div className="overflow-hidden mb-3">
						<h2 ref={subscribeTitleRef} className={`font-common  lg:border-0  border-zinc-500/20  text-[32px] lg:text-[42px] 2xl:text-[48px] text-left  lg:mx-auto  text-white lg:mb-0 mb-3 font-medium w-full tracking-tight `}>
							{t.subText}
						</h2>
					</div>
					<SubscriptionForm onSubscribe={subscribe} />
				</div>
			</div>
			<div id="blog" ref={blogRef} className="w-full py-20 mx-auto lg:mt-60">
				<div className="max-w-screen-xl px-4 mx-auto w-full gap-12">
					<div className="overflow-hidden mb-3">
						<h2 ref={blogTitleRef} className={` opacity-0 font-common text-[32px] lg:text-[42px] 2xl:text-[48px] text-black lg:mb-0 mb-3 font-bold w-full tracking-tight `}>
							Insights
						</h2>
					</div>

					<div className="">
						<RecentBlogs />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Home;
