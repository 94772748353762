import { useEffect } from "react";
import { CgChevronRight } from "react-icons/cg";
import { useLocation, useNavigate } from "react-router-dom";
import Safari from "../components/safari";

const CaseDetails = () => {
	const location = useLocation();
	const { project } = location.state || {};
	const navigate = useNavigate();


	useEffect(() => {
		if (!project) navigate("/projects")
	}, []);

	if (!project) return null;
	document.title = "Project " + project.title;
	return (
		<div className="pb-10">
			<div className="max-w-screen-xl mx-auto px-4 xl:px-0  mx-auto">
				<h2 className={`mb-2  text-2xl sm:text-4xl  text-center text-black  font-semibold w-[-webkit-fill-available] tracking-tight `}>
					{project.title}
				</h2>
				<h5 className="text-center font-inter  text-base sm:text-2xl ">{project.subtitle}</h5>
				<img src={project.image} alt={project.title} className='w-full border   mx-auto mt-10 border h-auto aspect-video object-cover object-center rounded-lg shadow-sm' />
			</div>

			<div className="max-w-screen-xl border-b  py-6 lg:py-20 2xl:px-0 px-4 grid grid-cols-1 gap-10 lg:grid-cols-3 mx-auto">
				<div className="cols-span-1 lg:border-0 border-b  lg:pb-0 pb-6 lg:col-span-1 lg:border-r ">
					{/* <h6 className="text-base font-semibold  font-inter   text-zinc-700 mb-5">{project.released}</h6> */}
					<div className="flex flex-row flex-wrap w-full items-center mb-4 lg:mb-6  gap-2 ">
						{project.tags.map((tag, index) => (
							<div key={index} className="bg-white text-black p-1 text-zinc-500 px-3 bg-zinc-100 text-xs lg:text-sm rounded ">
								{tag}
							</div>
						))}
					</div>
					<a href={project.link} target="_blank" rel="noreferrer" className="text-black hover:text-yellow-600 font-inter text-base transition font-normal flex gap-6 items-center">Visit website <CgChevronRight />
					</a>
				</div>
				<div className="cols-span-1 text-justify lg:col-span-2 lg:text-lg text-base">
					{project.content && project.content[0]}
				</div>
			</div>

			<div className="max-w-screen-xl flex flex-col-reverse lg:flex-row items-center mx-auto gap-10 lg:gap-10  my-32   mx-auto">
				<div className="w-full lg:text-lg  px-4 xl:px-0  text-justify text-base">
					{project.content && project.content[1]}
				</div>
				<div className={`${project.bg}  lg:w-full rounded`}>
					<img src={project.images && project.images[0]} alt={project.title} className='border-2 w-[-webkit-fill-available]  px-3 rounded mx-3 ' />
				</div>
			</div>

			<div className="max-w-screen-xl flex flex-col lg:flex-row items-center mx-auto gap-10 lg:gap-10  my-20 border-t border-b py-20   mx-auto">
				<div className={`${project.bg}  lg:w-full  rounded-lg`}>
					<img src={project.images && project.images[2]} alt={project.title} className=' border-2 w-[-webkit-fill-available]  px-3 rounded mx-3 ' />
				</div>
				<div className="w-full lg:text-lg  px-4 xl:px-0  text-justify text-base">
					{project.content && project.content[2]}
				</div>
			</div>
			<div className="max-w-screen-xl flex flex-col items-center w-full mx-3 lg:-1/2  mx-auto   my-32   mx-auto">
				<h2 className={`  text-2xl sm:text-4xl  text-center text-black  font-semibold w-[-webkit-fill-available] tracking-tight `}>
					{project.title}
				</h2>
				<div className="w-full lg:text-lg mt-4 px-4 xl:px-0  text-justify text-base">
				</div>
				<div className={` grid grid-cols-2 mt-10 gap-3 mx-auto w-[-webkit-fill-available] px-3   lg:w-1/2  rounded-lg`}>

					<img src={project.images && project.images[4]} alt={project.title} className='w-full border-2 rounded' />
					<img src={project.images && project.images[5]} alt={project.title} className='w-full border-2 rounded' />

				</div>
			</div>

		</div>
	)
}

export default CaseDetails;