import React, { useEffect, useRef, useState } from 'react';
import { motion, useTransform, useScroll, useSpring } from 'framer-motion';
import { cn } from '../tools/cn';

const TracingBeam = ({ children, className }) => {
	const ref = useRef(null);

	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ['start start', 'end start'],
	});

	const contentRef = useRef(null);
	const [svgHeight, setSvgHeight] = useState(0);

	useEffect(() => {
		if (contentRef.current) {
			setSvgHeight(contentRef.current.offsetHeight);
		}
	}, []);

	const y1 = useSpring(
		useTransform(scrollYProgress, [0, 0.8], [50, svgHeight]),
		{
			stiffness: 500,
			damping: 90,
		}
	);
	const y2 = useSpring(
		useTransform(scrollYProgress, [0, 1], [50, svgHeight - 200]),
		{
			stiffness: 500,
			damping: 90,
		}
	);

	return (
		<motion.div
			ref={ref}
			className={cn('relative  z-[9999]  h-full', className)}>
			<div className="absolute -mr-2  -right-2 top-0">
				<motion.div
					transition={{ duration: 0.2, delay: 0 }}
					animate={{
						boxShadow:
							scrollYProgress.get() > 0
								? 'none'
								: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
					}}
					className="ml-[27px] h-4 w-4 rounded-full border border-netural-200 shadow-sm flex items-center justify-center"
				>
					<motion.div
						transition={{ duration: 0.2, delay: 0.5 }}
						animate={{
							backgroundColor:
								scrollYProgress.get() > 0 ? 'white' : '#d4af37',
							borderColor:
								scrollYProgress.get() > 0 ? 'white' : '#d4af37',
						}}
						className="h-2 w-2 rounded-full border border-neutral-300 bg-white"
					/>
				</motion.div>
				<svg
					viewBox={`0 0 20 ${svgHeight}`}
					width="20"
					height={svgHeight} // Set the SVG height
					className="ml-4 block"
					aria-hidden="true"
				>
					<motion.path
						d={`M 1 0V -36 l 18 24 V ${svgHeight * 0.8} l -18 24V ${svgHeight}`}
						fill="none"
						stroke="#d4af37"
						strokeOpacity="0.16"
						transition={{ duration: 10 }}
					></motion.path>
					<motion.path
						d={`M 1 0V -36 l 18 24 V ${svgHeight * 0.8} l -18 24V ${svgHeight}`}
						fill="none"
						stroke="url(#gradient)"
						strokeWidth="1.25"
						className="motion-reduce:hidden"
						transition={{ duration: 10 }}
					></motion.path>
					<defs>
						<motion.linearGradient
							id="gradient"
							gradientUnits="userSpaceOnUse"
							x1="0"
							x2="0"
							y1={y1} // set y1 for gradient
							y2={y2} // set y2 for gradient
						>
							<stop stopColor="#d4af37" stopOpacity="0"></stop>
							<stop stopColor="#d4af37"></stop>
							<stop offset="0.325" stopColor="#d4af37"></stop>
							<stop offset="1" stopColor="#d4af37" stopOpacity="0"></stop>
						</motion.linearGradient>
					</defs>
				</svg>
			</div>
			<div ref={contentRef}>{children}</div>
		</motion.div>
	);
};

export default TracingBeam;