import createGlobe from "cobe";
import { useEffect, useRef } from "react";

const Globe = ({ className }) => {
	const canvasRef = useRef(null);
	const isMobile = window.innerWidth < 1024;

	useEffect(() => {
		let phi = 0;
		if (!canvasRef.current) return;
		const globe = createGlobe(canvasRef.current, {
			devicePixelRatio: 2,
			width: isMobile ? 300 * 2 : 400 * 2,
			height: isMobile ? 300 * 2 : 400 * 2,
			phi: 0,
			theta: 0,
			dark: 1,
			diffuse: 1.5,
			mapSamples: 10000,
			mapBrightness: 10,
			baseColor: [0.2, 0.2, 0.2],
			markerColor: [0.5, 0.5, 0.5],
			glowColor: [0.25, 0.15, 0.02],
			markers: [
				{ location: [52.3676, 4.9041], size: 0.1 },
			],
			onRender: (state) => {
				state.phi = phi;
				phi += isMobile ? 0.01 : 0.005;
			},
		});

		return () => {
			globe.destroy();
		};
	}, [isMobile]);

	return (
		<canvas
			ref={canvasRef}
			style={{ width: isMobile ? 300 : 400, height: isMobile ? 300 : 400, maxWidth: "100%", aspectRatio: 1 }}
			className={className}
		/>
	);
};

export default Globe;
