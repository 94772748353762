import React, { useEffect } from 'react';
import 'swiper/css';
import { useBlog } from '../tools/blog-context';
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';
import { convertTextToSlug } from '../tools/cn';

const RecentBlogs = () => {
	const navigate = useNavigate();
	const { loading, posts } = useBlog();
	const isMobile = window.innerWidth < 768;
	const slicedPosts = posts.slice(0, isMobile ? 2 : 4);
	useEffect(() => {
		AOS.init({
			duration: 700,
			once: true,
		});
	}, []);


	return (
		<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
			{slicedPosts.map((post, idx) => (
				<article onClick={() => navigate(`/insights/${convertTextToSlug(post.title)}`, { state: { post } })} class="  relative h-full grid-col-span-1  transition cursor-pointer hover:shadow-2xl">
					<img
						alt=""
						src={post.feature_image}
						class=" inset-0 h-full w-full  rounded object-cover"
					/>
					<div class=" absolute bottom-0 left-0 w-full bg-gradient-to-t backdrop-blur-xl h-fit from-gray-900/50 to-gray-900/50 ">
						<div class="py-4  px-4 flex flex-col w-full sm:p-9">
							<h3 class="text-[16px] lg:text-lg text-white">{post.title}</h3>
						</div>
					</div>
				</article>
			))}
		</div>


	)
}

export default RecentBlogs;