import React, { useState } from 'react';
import TextBlock from "../components/text-block";
import TextReveal from '../components/v2/text-reveal/text-reveal';


const translations = {
	nl: {
		serviceTitle: "Dienst",
		whatWeDoTitle: "Wat we doen",
		whatWeDoText: "Wij gebruiken een breed scala aan technologieën en tools om u te kunnen helpen, van het bouwen van apps, web-ontwikkeling tot het ontwerpen van UX/UI-interfaces tot smart contracts."
	},
	en: {
		serviceTitle: "Service",
		whatWeDoTitle: "What we do",
		whatWeDoText: "We use a wide range of technologies and tools to assist you, from building apps and web development to designing UX/UI interfaces to smart contracts."
	}
};

const Services = {
	en: [
		{
			topic: "Development",
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-code-xml"><path d="m18 16 4-4-4-4" /><path d="m6 8-4 4 4 4" /><path d="m14.5 4-5 16" /></svg>,
			details: [
				{
					title: "Full-stack development",
					description: "Elevate your website with our comprehensive full-stack development services, turning your vision into reality. we handle all aspects of web development, from front-end design to back-end solutions, ensuring a seamless and robust user experience."
				},
				{
					title: "Ecommerce and landing pages",
					description: "From ecommerce platforms to engaging landing pages, we create websites that stand out, making an impression among competitors. our approach is both innovative and reliable, ensuring your online presence is impactful and effective."
				},
				{
					title: "Dashboards and back-end solutions",
					description: "We develop sophisticated dashboards and back-end solutions tailored to your business needs, providing you with powerful tools to manage your data and operations efficiently."
				}
			]
		},
		{
			topic: "Design",
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-frame"><line x1="22" x2="2" y1="6" y2="6" /><line x1="22" x2="2" y1="18" y2="18" /><line x1="6" x2="6" y1="2" y2="22" /><line x1="18" x2="18" y1="2" y2="22" /></svg>,
			details: [
				{
					title: "Visual design",
					description: "Our designs are crafted to be visually stunning, making your website a work of art. we focus on aesthetics and usability, ensuring that your site is both beautiful and functional."
				},
				{
					title: "User experience (ux) design",
					description: "We prioritize user experience in our design process, creating intuitive and engaging interfaces that keep visitors on your site and encourage interaction."
				},
				{
					title: "Brand identity and graphic design",
					description: "We help you build a strong brand identity through thoughtful graphic design, ensuring that your website reflects your brand's personality and values."
				}
			]
		},
		{
			topic: "Maintenance",
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" /></svg>,
			details: [
				{
					title: "Swift deployments",
					description: "We ensure your website is swiftly deployed, minimizing downtime and getting your site live quickly and efficiently."
				},
				{
					title: "Secure hosting",
					description: "Our hosting solutions are secure and reliable, ensuring your website is always available to your audience and protected against threats."
				},
				{
					title: "Maintenance and optimization",
					description: "We ensure your website operates efficiently, with continuous performance optimization and robust security measures to keep it running smoothly and safely."
				}
			]
		}
	],
	nl: [
		{
			topic: "Ontwikkeling",
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-code-xml"><path d="m18 16 4-4-4-4" /><path d="m6 8-4 4 4 4" /><path d="m14.5 4-5 16" /></svg>,
			details: [
				{
					title: "Full-stack ontwikkeling",
					description: "Verhoog uw website met onze uitgebreide full-stack ontwikkelingsdiensten, die uw visie werkelijkheid maken. we behandelen alle aspecten van webontwikkeling, van front-end design tot back-end oplossingen, en zorgen voor een naadloze en robuuste gebruikerservaring."
				},
				{
					title: "Ecommerce en landingspagina's",
					description: "Van ecommerce platforms tot boeiende landingspagina's, wij maken websites die opvallen en indruk maken op concurrenten. onze aanpak is zowel innovatief als betrouwbaar, zodat uw online aanwezigheid impactvol en effectief is."
				},
				{
					title: "Dashboards en back-end oplossingen",
					description: "We ontwikkelen geavanceerde dashboards en back-end oplossingen die zijn afgestemd op uw zakelijke behoeften, en bieden u krachtige tools om uw gegevens en operaties efficiënt te beheren."
				}
			]
		},
		{
			topic: "Ontwerp",
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-frame"><line x1="22" x2="2" y1="6" y2="6" /><line x1="22" x2="2" y1="18" y2="18" /><line x1="6" x2="6" y1="2" y2="22" /><line x1="18" x2="18" y1="2" y2="22" /></svg>, details: [
				{
					title: "Visueel ontwerp",
					description: "Onze ontwerpen zijn visueel verbluffend, waardoor uw website een kunstwerk wordt. we focussen op esthetiek en bruikbaarheid, zodat uw site zowel mooi als functioneel is."
				},
				{
					title: "Gebruikerservaring (ux) ontwerp",
					description: "Wij geven prioriteit aan de gebruikerservaring in ons ontwerpproces, en creëren intuïtieve en boeiende interfaces die bezoekers op uw site houden en aanmoedigen tot interactie."
				},
				{
					title: "Merkidentiteit en grafisch ontwerp",
					description: "Wij helpen u een sterke merkidentiteit op te bouwen door doordacht grafisch ontwerp, zodat uw website de persoonlijkheid en waarden van uw merk weerspiegelt."
				}
			]
		},
		{
			topic: "Onderhoud",
			icon: <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-wrench"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" /></svg>,
			details: [
				{
					title: "Snelle implementaties",
					description: "We zorgen ervoor dat uw website snel wordt geïmplementeerd, waardoor downtime wordt geminimaliseerd en uw site snel live gaat."
				},
				{
					title: "Veilige hosting",
					description: "Onze hostingoplossingen zijn veilig en betrouwbaar, zodat uw website altijd beschikbaar is voor uw publiek en beschermd is tegen bedreigingen."
				},
				{
					title: "Onderhoud en optimalisatie",
					description: "We zorgen ervoor dat uw website efficiënt werkt, met continue prestatie-optimalisatie en robuuste beveiligingsmaatregelen om deze soepel en veilig te laten draaien."
				}
			]
		}
	]
};

const Service = ({ locale }) => {
	const t = translations[locale];
	const s = Services[locale];
	if (!t) {
		return <div>Translation not available</div>;
	}

	return (
		<section id="page-services" >
			<div id="hero__services" className=" max-w-screen-xl mx-auto mb-20  flex flex-col  2xl:px-0 px-4 justify-center ">
				{/* <TextBlock
					isTitle={true}
					isHero={false}
					title={t.whatWeDoTitle}
					center={false}
					text={<div className="text-[18px] lg:text-xl  font-normal text-black leading-[160%] md:leading-[160%]">{t.whatWeDoText}</div>}
				/> */}
				<div className="2xl:w-2/3 w-full">
					<TextReveal>
						<span className="text-[35px] poppins lg:text-[45px] pb-3  text-black font-semibold xl:text-[60px] 2xl:text-[70px]">{t.whatWeDoTitle}</span>
						<span className="text-[16px] lg:text-xl lg:w-2/3  font-normal eading-[160%] md:leading-[160%]">{t.whatWeDoText}</span>
					</TextReveal>
				</div>
			</div>
			<div className='w-full flex flex-col max-w-screen-xl mx-auto flex flex-col lg:gap-32  pb-20 2xl:px-0 px-4'>
				{Services[locale].map((service, idx) => (
					<div className='w-full flex flex-col lg:flex-row lg:even:flex-row-reverse first:mt-0  mt-20  '>
						<h2 className='text-[24px] w-full  lg:justify-center mx-auto flex flex-row lg:text-center lg:mx-auto    items-center  gap-3  font-semibold lg:mb-7 mb-3 text-black xl:text-[44px]'>
							{service.topic}
						</h2>
						<div className='flex w-full flex-col lg:gap-10 gap-0 '>
							{service.details.map((detail, index) => (
								<>
									<div className="lg:hidden px-0 mx-0 overflow-hidden gap-0 collapse collapse-arrow border-t rounded-none ">
										<input type="radio" name="my-accordion-2" defaultChecked />
										<h5 className="text-black collapse-title pl-0   font-common w-full text-left text-[18px] font-medium pr-10  ">{detail.title}</h5>
										<div className="collapse-content px-0 ">
											<p className='text-lg text-zinc-600 font-normal'>{detail.description}</p>
										</div>
									</div>
									<div className={`hidden lg:flex w-full  flex ${idx === 1 && "lg:text-right"} flex-col gap-2`}>
										<h5 className="text-black  font-semibold font-common  text-[22px]">{detail.title}</h5>
										<p className='text-[18px] lg:text-xl  font-normal text-black  leading-[160%]'>{detail.description}</p>
									</div >
								</>
							))}
						</div>
					</div>
				))}


			</div>
		</section>
	)
}

export default Service;
