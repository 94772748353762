import { useLocation, useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { FaChevronLeft, FaChevronUp } from "react-icons/fa";
import { RiCalendar2Fill, RiFileCopyFill, RiLinkedinFill, RiWhatsappFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { convertSlugToText } from "../tools/cn";
import { getPostsByTitle } from "../tools/blog-context";
import { useEffect } from "react";
import TracingBeam from "../components/beam";

const InsightPost = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { post } = location.state || {};
	const { postSlug } = useParams();

	useEffect(() => {
		document.body.style.overflow = "auto";
	}, [postSlug]);

	if (!post) {
		getPostsByTitle(convertSlugToText(postSlug)).then((posts) => {
			if (posts.length > 0) {
				navigate(`/insights/${postSlug}`, { state: { post: posts[0] } });
			}
			else {
				navigate("/insights");
			}
		});
	}
	else {
		const currentUrl = window.location.href;
		const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;
		const whatsappMessage = `Check out this blog: ${currentUrl}`;
		const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`;

		const copyToClipboard = () => {
			navigator.clipboard.writeText(currentUrl).then(() => {
				toast('URL copied to clipboard');
			}).catch(err => {
				console.error('Failed to copy: ', err);
			});
		};

		return (
			<div className="max-w-screen-xl mx-auto  2xl:px-0 px-4  ">
				<div onClick={() => navigate("/insights")} className="cursor-pointer   sticky top-7 -ml-14 -mb-8  text-base font-normal text-zinc-600 hover:text-black flex items-start gap-2 font-inter"><FaChevronLeft size="20" className="text-zinc-400 hover:text-black" /></div>
				<h2 className={`   pr-5 font   text-2xl sm:text-3xl    font-semibold w-[-webkit-fill-available] tracking-tight `}>
					{post.title}
				</h2>

				<img
					alt=""
					src={post.feature_image}
					className="hidden md:flex my-10 relative z-[1000] rounded-lg shadow-lg w-[-webkit-fill-available]"
				/>
				<div className="flex  flex-col gap-4 md:gap-20 md:flex-row items-start">
					<div className="md:sticky top-6  md:my-0 md:w-2/5 ">
						<div className="w-[-webkit-fill-available]">
							<time datetime="2022-10-10" className="md:scale-1 scale-[0.9]  -ml-4 flex md:items-center items-end gap-3   md:mt-0 mt-3 text-lg md:text-lg font-medium text-zinc-400">
								<RiCalendar2Fill size="22" className="" />
								{new Date(post.created_at).toLocaleDateString()}
							</time>
						</div>
						<div className="flex flex-wrap flex-row md:gap-1 mt-3 md:border-b gap-2 md:border-t md:py-4  py-2  ">
							{post.tags.map((tag) => (
								<div key={tag.id} className="text-sm md:text-base bg-zinc-100 rounded-lg md:w-fit font-normal px-2  py-1 last:border-0 text-zinc-600 ">{tag.name}</div>
							))}
						</div>
						<div className="flex flex-row text-zinc-400 gap-4 items-center cursor-pointer md:mt-4 mt-2">
							<a href={linkedInShareUrl} target="_blank" rel="noopener noreferrer">
								<RiLinkedinFill size="28" className="hover:text-black" />
							</a>
							<div onClick={() => window.open(whatsappShareUrl, '_blank', 'noopener,noreferrer')}>
								<RiWhatsappFill size="28" className="hover:text-black" />
							</div>
							<div href="#" onClick={copyToClipboard}>
								<RiFileCopyFill size="28" className="hover:text-black" />
							</div>
						</div>

					</div>
					<img
						alt=""
						src={post.feature_image}
						className="md:hidden flex rounded-2xl shadow-xl mb-4 md:mb-0  w-[-webkit-fill-available]"
					/>
					<div className="content-blog w-[-webkit-fill-available] text-base flex flex-col gap-4  font-normal" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.html) }}></div>
					<div className="md:sticky top-6 text-zinc-500 md:my-0 flex flex-wrap flex-row gap-1 md:w-1/5 ">

					</div>

				</div>
				<div onClick={() => window.scrollTo(0, 0)} className="text-base w-full mt-10 -mb-10  font-normal text-zinc-500 flex items-center gap-2 font-inter">
					<FaChevronUp size="30" className="mx-auto cursor-pointer  text-zinc-400 hover:text-black transition-all hover:translate-y-[-5px]" />
				</div>
			</div>
		)
	}
}

export default InsightPost;

