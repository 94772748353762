import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
	return twMerge(clsx(inputs));
}


export function convertTextToSlug(text) {
	return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
}

export function convertSlugToText(slug) {
	return slug.replace(/-/g, ' ');
}