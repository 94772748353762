import "./text-reveal.css";

const TextReveal = ({ children }) => {

	return (
		<h1 class="home-title">
			{children}
		</h1>
	)
}

export default TextReveal;