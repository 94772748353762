import React from 'react';
import TextReveal from '../components/v2/text-reveal/text-reveal.jsx';
import RegisterFreeSite from '../components/v2/register-free-site/register-free-site.jsx';
import { PiBroomLight, PiPencilRulerLight, PiSlidersLight } from 'react-icons/pi';
import { Link } from 'react-router-dom';
const Collaborating = ({ locale }) => {
	if (locale === 'en') {
		return (
			<div className='mx-auto'>
				<div className="max-w-screen-xl 2xl:px-0 px-4 flex flex-col justify-center mb-10 mx-auto">
					<TextReveal>
						<span className="text-[35px] lg:text-[45px] poppins text-black font-bold xl:text-[60px] 2xl:text-[65px]">Collaborate for a better tomorrow</span>
						<span className="text-[20px] poppins lg:text-[22px] text-[var(--primary)] font-medium xl:text-[25px] 2xl:text-[35px]">Free website for your foundation</span>
					</TextReveal>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20'>
					<p>
						At Walhallah, we develop inspiring and impactful digital experiences. We believe that modern technology and distinctive designs are means to achieve positive changes, even for good causes. A well-designed and functional website can increase visibility, attract more donations, and mobilize broader support.
					</p>
					<p className='mt-3'>
						That's why we offer two CBF.nl registered good causes organizations a free, tailored website every year.
					</p>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20 lg:mb-32'>
					<h4 className="text-[24px] lg:text-[28px] leading-[160%] font-semibold mt-10 mb-5 ">What we offer</h4>
					<div className='flex bg-zinc-100 flex-col lg:flex-row gap-2 items-start p-5 rounded-lg mb-3'>
						<PiPencilRulerLight size="40" className='text-black min-w-10 lg:min-w-[unset]' />
						<div>
							<h6 className='text-xl text-black font-medium'>Modern design</h6>
							<p>A user-friendly and responsive design that communicates the mission and values of your organization.</p>
						</div>
					</div>
					<div className='flex bg-zinc-100 flex-col lg:flex-row gap-2 items-start p-5 rounded-lg mb-3'>
						<PiSlidersLight size="40" className='text-black min-w-10 lg:min-w-[unset]' />
						<div>
							<h6 className='text-xl text-black font-medium'>Full functionality</h6>
							<p>Including donation page, volunteer registration, event management, and more, tailored to your specific needs.</p>
						</div>
					</div>
					<div className='flex bg-zinc-100 flex-col lg:flex-row gap-2 items-start p-5 rounded-lg mb-3'>
						<PiBroomLight size="40" className='text-black min-w-10 lg:min-w-[unset]' />
						<div>
							<h6 className='text-xl text-black font-medium'>Maintenance and support</h6>
							<p>Initial training and support so that your team can easily manage the website.</p>
						</div>
					</div>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20 lg:mb-32'>
					<h4 className="text-[24px] lg:text-[28px] leading-[160%] text-center font-semibold mt-10 mb-5 ">Why we do this</h4>
					<p>Our mission is to not only create beautiful websites, but also to make a positive impact. By working together with organizations like yours, we want to use our expertise to increase your reach and effectiveness.</p>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20 lg:mb-32'>
					<h4 className="text-[24px] lg:text-[28px] leading-[160%] text-center font-semibold mt-10 mb-5 ">How it works</h4>
					<div className='flex flex-col relative lg:flex-row items-stretch items-start p-3'>
						<div className='flex w-full flex-col  gap-3 lg:items-start py-3 lg:px-10 relative'>
							<div className=' mx-auto bg-black  text-base rounded-full w-10 h-10 flex justify-center items-center flex-col poppins font-bold text-white'>1
								<div className='hidden z-[-1] lg:flex right-0 absolute h-[1px] w-1/2 bg-zinc-300'></div>
							</div>
							<div className='text-center lg:mt-5'>
								<h6 className='text-xl text-black font-medium'>Application</h6>
								<p>Describe via the form below how a new website can support your goals.</p>
							</div>
						</div>
						<div className='flex w-full flex-col relative  gap-3 lg:items-start py-3 lg:px-10 relative'>
							<div className=' mx-auto bg-black  text-base rounded-full w-10 h-10 flex justify-center items-center flex-col poppins font-bold text-white'>2
								<div className='hidden z-[-1] lg:flex absolute h-[1px] left-0 w-full bg-zinc-300'></div>
							</div>
							<div className='text-center lg:mt-5'>
								<h6 className='text-xl text-black font-medium'>Selection</h6>
								<p>We select two organizations every year to receive this free service.</p>
							</div>
						</div>
						<div className='flex w-full flex-col relative  gap-3 items-start py-3 lg:px-10'>
							<div className=' mx-auto bg-black  text-base rounded-full w-10 h-10 flex justify-center items-center flex-col poppins font-bold text-white'>3
								<div className='hidden z-[-1] lg:flex absolute h-[1px] left-0 w-1/2 bg-zinc-300'></div>
							</div>
							<div className='text-center lg:mt-5'>
								<h6 className='text-xl text-black font-medium'>Collaboration</h6>
								<p>We work closely with your team to develop a website that perfectly fits your needs and goals.</p>
							</div>
						</div>
					</div>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 flex flex-col gap-3 mx-auto'>
					<p>
						This collaboration offers a unique chance to create valuable social impact and showcase our skills at the same time. We hope you'll join us and look forward to learning more about your great work.
					</p>

					<p>
						For any other questions or information, you can contact us via the <Link className='text-blue-500 underline' to="/contact">contact page</Link>. We're ready to work together on a project that benefits not only your organization but also the community as a whole.
					</p>
				</div>
				<div className='w-full bg-zinc-100 mt-20 py-20'>
					<RegisterFreeSite locale={locale} />
				</div>
			</div>
		)
	}
	else if (locale === 'nl') {
		return (
			<div className='mx-auto'>
				<div className="max-w-screen-xl 2xl:px-0 px-4 flex flex-col justify-center mb-10 mx-auto ">
					<TextReveal>
						<span className="text-[35px] lg:text-[45px]  poppins  text-black font-bold xl:text-[60px] 2xl:text-[65px]">Samenwerken voor een beter morgen</span>
						<span className="text-[20px] poppins lg:text-[22px]  text-[var(--primary)] font-medium xl:text-[25px] 2xl:text-[35px]">Kosteloze website voor uw stichting</span>
					</TextReveal>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20'>
					<p>
						Wij van Walhallah ontwikkelen inspirerende en impactvolle digitale ervaringen.
						Wij geloven dat moderne technologie en onderscheidende designs middelen zijn om positieve veranderingen te realiseren, ook voor goede doelen. Een goed ontworpen en functionele website kan de zichtbaarheid vergroten, meer donaties aantrekken, en bredere steun mobiliseren.
					</p>
					<p className='mt-3 '>
						Daarom bieden wij jaarlijks twee CBF.nl geregistreerde goede doelen organisaties een kosteloze, op maat gemaakte website aan.
					</p>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20 lg:mb-32 '>
					<h4 className="text-[24px] lg:text-[28px] leading-[160%] font-semibold mt-10 mb-5 ">Wat wij bieden
					</h4>
					<div className='flex bg-zinc-100 flex-col lg:flex-row gap-2 items-start p-5 rounded-lg mb-3 '>
						<PiPencilRulerLight size="40" className='text-black min-w-10 lg:min-w-[unset]' />
						<div>
							<h6 className='text-xl text-black font-medium'>Modern ontwerp</h6>
							<p>Een gebruiksvriendelijk en responsief ontwerp dat de missie en waarden van uw organisatie krachtig communiceert.</p>
						</div>
					</div>
					<div className='flex bg-zinc-100 flex-col lg:flex-row gap-2 items-start p-5 rounded-lg mb-3'>
						<PiSlidersLight size="40" className='text-black min-w-10 lg:min-w-[unset]' />
						<div>
							<h6 className='text-xl text-black font-medium'>Volledige functionaliteit</h6>
							<p>Inclusief donatiepagina, vrijwilligersregistratie, evenementenbeheer en meer, aangepast aan uw specifieke behoeften.</p>
						</div>
					</div>
					<div className='flex bg-zinc-100 flex-col lg:flex-row gap-2 items-start p-5 rounded-lg mb-3 '>
						<PiBroomLight size="40" className='text-black min-w-10 lg:min-w-[unset]' />
						<div>
							<h6 className='text-xl text-black font-medium'>Onderhoud en ondersteuning</h6>
							<p>Initiële training en ondersteuning zodat uw team de website eenvoudig kan beheren.</p>
						</div>
					</div>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20 lg:mb-32 '>
					<h4 className="text-[24px] lg:text-[28px] leading-[160%] font-semibold mt-10 mb-5 ">Waarom wij dit doen</h4>
					<p>Onze missie is om niet alleen mooie websites te creëren, maar ook om een positieve impact te maken. Door samen te werken met organisaties zoals de uwe, willen we onze expertise inzetten om uw bereik en effectiviteit te vergroten.</p>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 mx-auto mb-20 lg:mb-32 '>
					<h4 className="text-[24px] lg:text-[28px] leading-[160%] text-center font-semibold mt-10 mb-5 ">Hoe het werkt</h4>
					<div className='flex flex-col relative  lg:flex-row items-stretch  items-start p-3 '>
						<div className='flex w-full  flex-col gap-3 lg:items-start py-3 lg:px-10 relative'>
							<div className=' mx-auto bg-black  text-base rounded-full w-10 h-10 flex justify-center items-center flex-col poppins font-bold text-white'>1
								<div className='hidden z-[-1] lg:flex right-0 absolute h-[1px]   w-1/2 bg-zinc-300'></div>
							</div>
							<div className='text-center lg:mt-5'>
								<h6 className='text-xl text-black font-medium'>Aanmelding</h6>
								<p>Beschrijf via het formulier onderaan de pagina hoe een nieuwe website uw doelen kan ondersteunen.</p>
							</div>
						</div>
						<div className='flex w-full flex-col   gap-3 lg:items-start py-3 lg:px-10 relative'>
							<div className=' mx-auto bg-black  text-base rounded-full w-10 h-10 flex justify-center items-center flex-col poppins font-bold text-white'>2
								<div className='hidden z-[-1] lg:flex absolute h-[1px] left-0  w-full bg-zinc-300'></div>

							</div>
							<div className='text-center lg:mt-5'>
								<h6 className='text-xl text-black font-medium'>Selectie</h6>
								<p>
									Jaarlijks selecteren wij twee organisaties die wij deze kosteloze dienst
									aanbieden.
								</p>
							</div>
						</div>
						<div className='flex w-full flex-col  relative  gap-3 items-start py-3 lg:px-10 '>
							<div className=' mx-auto bg-black  text-base rounded-full w-10 h-10 flex justify-center items-center flex-col poppins font-bold text-white'>3
								<div className='hidden z-[-1] lg:flex absolute h-[1px] left-0  w-1/2 bg-zinc-300'></div>
							</div>
							<div className='text-center lg:mt-5 '>
								<h6 className='text-xl text-black font-medium'>Samenwerking</h6>
								<p>
									Wij werken nauw samen met uw team om een website te
									ontwikkelen die perfect aansluit bij uw behoeften en doelen.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='max-w-screen-xl 2xl:px-0 px-4 flex flex-col gap-3 mx-auto'>
					<p>
						Deze samenwerking biedt een unieke kans om waardevolle sociale impact te creëren en tegelijkertijd onze vaardigheden te tonen.
						Wij hopen dat u deze kans met ons wilt aangaan en kijken ernaar uit om meer te leren over uw geweldige werk.
					</p>

					<p>
						Voor overige vragen of informatie kunt u contact met ons opnemen via de <Link className='text-blue-500 underline' to="/contact">contactpagina</Link>.
						Wij staan klaar om samen te werken aan een project dat niet alleen uw organisatie ten goede komt, maar ook de gemeenschap als geheel.</p>
				</div>
				<div className='w-full bg-zinc-100 mt-20 py-20 '>
					<RegisterFreeSite locale={locale} />
				</div>
			</div>
		)
	}
};

export default Collaborating;
