import React from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './layout';
import './styles/base.scss';
import 'aos/dist/aos.css';
import "./styles/locomotive.scss";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Layout />
	</React.StrictMode>
);