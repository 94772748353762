import React, { useState } from 'react';
import { IoArrowForwardCircleSharp } from 'react-icons/io5';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import Button from './button';
const SubscriptionForm = ({ onSubscribe }) => {

	const [email, setEmail] = useState(null);
	const [consent, setConsent] = useState(false);
	const [message, setMessage] = useState('');

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!consent) {
			setMessage('You must agree to the terms to subscribe.');
			return;
		}
		try {
			await onSubscribe(email);
			setMessage('Check your email for the subscription confirmation link!');
		} catch (error) {
			console.error('Error:', error);
			setMessage('There was an error with your subscription. Please try again.');
		}
	};

	return (
		<form onSubmit={handleSubmit} className=' w-full flex flex-col gap-3 lg:gap-0 justify-center'>
			<div className=' flex flex-col lg:flex-row items-center gap-3 border-zinc-200 lg:gap-3 w-full '>
				<div className={`  w-full relative z-0 transition-all focus-within:z-10 overflow-hidden`}>
					<input
						type="email"
						id={"email"}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
						placeholder=""
						className={`peer w-full border placeholder:color-black text-white peer-focus:placeholder:text-zinc-500 border-neutral-700 text-xl bg-transparent px-4 py-4 lg:py-6  text-base/6 text-neutral-950 ring-4 ring-transparent transition focus:border-[var(--primary)] focus:outline-none focus:ring-neutral-950/5 group-first:rounded-t rounded`}
					/>
					<label
						htmlFor={"email"}
						className={`pointer-events-none absolute text-xl left-4 lg:left-6  top-8 lg:top-7 origin-left text-base/6  ${email && "opacity-0"}  text-neutral-500 transition-all duration-200 peer-focus:-translate-y-7 peer-focus:opacity-0 peer-focus:scale-75 peer-focus:font-normal peer-focus:text-neutral-950 peer-[:not(:placeholder-shown)]:-translate-y-4 peer-[:not(:placeholder-shown)]:scale-75 peer-[:not(:placeholder-shown)]:font-normal peer-[:not(:placeholder-shown)]:text-neutral-950`}
					>
						Leave your email
					</label>
				</div>
				<Button type="submit" disabled={!email} onClick={handleSubmit} size="xl" variant="secondary" hasborder={true}>SUBSCRIBE<IoArrowForwardCircleSharp size="18" /></Button>
			</div>
			<div className='flex flex-col lg:grid lg:gap-0 gap-3  lg:mt-2.5  lg:grid-cols-4  w-full items-center  justify-start lg:justify-between'>
				<div onClick={() => setConsent(!consent)} className='h-full w-full gap-3  text-zinc-400 justify-left flex items-center text-sm lg:text-base   border-zinc-500/30'>
					{consent ? <GrCheckboxSelected size="22" className='text-[var(--primary)]' /> : <GrCheckbox size="24" />}
					I agree to receive updates.
				</div>


			</div>
			{message && <p className='text-zinc-500'>{message}</p>}
		</form >
	);
};

export default SubscriptionForm;
