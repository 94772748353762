import React, { createContext, useContext, useState, useEffect } from 'react';
import GhostContentAPI from '@tryghost/content-api';

const BlogContext = createContext();

const api = new GhostContentAPI({
	url: 'https://walhallah.ghost.io',
	key: process.env.REACT_APP_GHOST_API_KEY,
	version: 'v5.0'
});

const fetchInterval = 5 * 60 * 1000;

export const getPosts = async () => {
	try {
		const posts = await api.posts.browse({ limit: 'all', include: 'tags' });
		console.log(posts);
		return posts;
	} catch (err) {
		console.error(err);
		return [];
	}
};

export const getPostsByTitle = async (title) => {
	try {
		const posts = await getPosts();
		return posts.filter(post => post.title.toLowerCase().includes(title.toLowerCase()));
	} catch (err) {
		console.error(err);
		return [];
	}
};


export const BlogProvider = ({ children }) => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchPosts = async () => {
			const cachedData = localStorage.getItem('blogPosts');
			if (cachedData) {
				const { posts: cachedPosts, timestamp } = JSON.parse(cachedData);
				const now = new Date().getTime();
				if (now - timestamp < fetchInterval) {
					setPosts(cachedPosts);
					setLoading(false);
				} else {
					const fetchedPosts = await getPosts();
					setPosts(fetchedPosts);
					localStorage.setItem('blogPosts', JSON.stringify({ posts: fetchedPosts, timestamp: now }));
					setLoading(false);
				}
			} else {
				const fetchedPosts = await getPosts();
				setPosts(fetchedPosts);
				const now = new Date().getTime();
				localStorage.setItem('blogPosts', JSON.stringify({ posts: fetchedPosts, timestamp: now }));
				setLoading(false);
			}
		};

		fetchPosts();
		const intervalId = setInterval(fetchPosts, fetchInterval);
		return () => clearInterval(intervalId);
	}, []);

	const subscribe = async (email) => {
		try {
			const response = await fetch('https://walhallah.ghost.io/members/api/send-magic-link/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
					emailType: 'subscribe',
				}),
			});

			if (!response.ok) {
				throw new Error('Failed to subscribe');
			}
		} catch (error) {
			throw error;
		}
	};

	return (
		<BlogContext.Provider value={{ posts, loading, subscribe }}>
			{children}
		</BlogContext.Provider>
	);
};



export const useBlog = () => {
	return useContext(BlogContext);
};
