import React from 'react';
import TextReveal from '../components/v2/text-reveal/text-reveal';
import MP4 from '../assets/pwa.mp4';
import { IoArrowForwardCircleSharp, IoCloudDownload, IoCloudOffline, IoLayers, IoNotifications, IoShieldCheckmark, IoSpeedometer } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Button from "../components/button";
const translations = {
	nl: {
		heroTitle: "De Toekomst van Webontwikkeling",
		heroSubtitle: "Ontdek hoe Progressive Web Apps (PWA's) de beste eigenschappen van mobiele applicaties en websites combineren om snelle, betrouwbare en boeiende gebruikerservaringen te bieden. Verbeter uw digitale aanwezigheid met een naadloze oplossing die moeiteloos werkt op alle apparaten.",
		videoDescription: "In deze video laten we zien waarom Progressive Web Apps een revolutionaire verandering zijn in moderne webontwikkeling. Ontdek hoe PWA's uw digitale strategie kunnen transformeren en uw gebruikers een ongeëvenaarde browse-ervaring kunnen bieden.",
		whyChooseTitle: "Waarom kiezen voor Progressive Web Apps?",
		whyChooseText1: "Progressive Web Apps (PWA's) combineren het beste van web- en mobiele apps en bieden een snelle, betrouwbare en boeiende ervaring op alle apparaten. Ze bieden een app-achtige ervaring rechtstreeks vanuit de browser, werken offline en laden snel, zelfs op langzame netwerken.",
		whyChooseText2: "PWA's zijn responsief, kosteneffectief en gemakkelijker te onderhouden dan traditionele mobiele apps, waardoor ze een uitstekende keuze zijn voor bedrijven die hun online aanwezigheid willen versterken.",
		uspTitle: "Waarom gebruik maken van Progressive Web Apps?",
		uspList: [
			{
				title: "Offline mogelijkheden",
				description: "Houd gebruikers betrokken, zelfs wanneer ze offline zijn, en zorg voor consistente toegang tot uw inhoud.",
				icon: <IoCloudOffline size="34" />,
			},
			{
				title: "Snellere laadtijden",
				description: "Profiteer van caching en geoptimaliseerde prestaties, waardoor gebruikers snel toegang hebben tot uw inhoud.",
				icon: <IoSpeedometer size="34" />,
			},
			{
				title: "Compatibiliteit op meerdere platforms",
				description: "PWA's werken naadloos op alle apparaten, van desktops tot smartphones, waardoor aparte apps overbodig worden.",
				icon: <IoLayers size="34" />,
			},
			{
				title: "Push-meldingen",
				description: "Betrek gebruikers opnieuw met tijdige updates en herinneringen, rechtstreeks vanuit hun browser.",
				icon: <IoNotifications size="34" />,
			},
			{
				title: "Eenvoudige updates",
				description: "Voer automatisch updates uit zonder dat gebruikers nieuwe versies hoeven te downloaden, zodat ze altijd de nieuwste functies hebben.",
				icon: <IoCloudDownload size="34" />,
			},
			{
				title: "Verbeterde beveiliging",
				description: "PWA's maken gebruik van HTTPS om veilige gegevensoverdracht te garanderen en de privacy van gebruikers te beschermen, wat hen tot een vertrouwde keuze maakt voor zowel gebruikers als bedrijven.",
				icon: <IoShieldCheckmark size="34" />,
			}
		],
		contactTitle: "Klaar om uw digitale aanwezigheid te transformeren?",
		contactText: "Neem vandaag nog contact met ons op om te bespreken hoe we een PWA kunnen bouwen die aan uw unieke behoeften voldoet en de verwachtingen van uw gebruikers overtreft.",
		contactButton: "Neem contact op"
	},
	en: {
		heroTitle: "The Future of Web Development",
		heroSubtitle: "Discover how Progressive Web Apps (PWAs) combine the best features of mobile applications and websites to deliver fast, reliable, and engaging user experiences. Upgrade your digital presence with a seamless solution that works effortlessly across all devices.",
		videoDescription: "In this video, we break down what makes Progressive Web Apps a game-changer for modern web development. Discover how PWAs can revolutionize your digital strategy and provide your users with an unmatched browsing experience.",
		whyChooseTitle: "Why choose Progressive Web Apps?",
		whyChooseText1: "Progressive Web Apps (PWAs) blend the best of web and mobile apps, delivering a fast, reliable, and engaging experience across all devices. They provide an app-like experience directly from the browser, working offline and loading quickly even on slow networks.",
		whyChooseText2: "PWAs are responsive, cost-effective, and easier to maintain than traditional mobile apps, making them an excellent choice for businesses looking to enhance their online presence.",
		uspTitle: "Why use Progressive Web Apps?",
		uspList: [
			{
				title: "Offline Capabilities",
				description: "Keep users engaged even when they’re offline, ensuring consistent access to your content.",
				icon: <IoCloudOffline size="34" />,
			},
			{
				title: "Faster Load Times",
				description: "Benefit from caching and optimized performance, offering users quick access to your content.",
				icon: <IoSpeedometer size="34" />,
			},
			{
				title: "Cross-Platform Compatibility",
				description: "PWAs work seamlessly on all devices, from desktops to smartphones, eliminating the need for separate apps.",
				icon: <IoLayers size="34" />,
			},
			{
				title: "Push Notifications",
				description: "Re-engage users with timely updates and reminders, directly from their browsers.",
				icon: <IoNotifications size="34" />,
			},
			{
				title: "Easy Updates",
				description: "Deploy updates automatically without the need for users to download new versions, ensuring they always have the latest features.",
				icon: <IoCloudDownload size="34" />,
			},
			{
				title: "Enhanced Security",
				description: "PWAs leverage HTTPS to ensure secure data transmission and protect user privacy, making them a trusted choice for both users and businesses.",
				icon: <IoShieldCheckmark size="34" />,
			}
		],
		contactTitle: "Ready to transform your digital presence?",
		contactText: "Contact us today to discuss how we can build a PWA that meets your unique needs and exceeds your users' expectations.",
		contactButton: "Contact Us"
	}
};

const PWA = ({ locale }) => {
	const t = translations[locale];
	const navigate = useNavigate();

	return (
		<section id="page-pwa" className='bg-white' >
			{/* Hero Section */}
			<div id="hero__pwa" className="max-w-screen-xl px-6 2xl:px-0 px-4 mx-auto py-10">
				<div className="2xl:w-2/3 w-full mb-10">
					<TextReveal>
						<span className="text-[35px] lg:text-[45px] pb-3 poppins text-black font-semibold xl:text-[60px] 2xl:text-[70px]">
							{t.heroTitle}
						</span>
						<span className="text-[16px] lg:text-xl lg:w-2/3 font-normal leading-[160%] md:leading-[160%] mt-4">
							{t.heroSubtitle}
						</span>
					</TextReveal>
				</div>
			</div>

			<section>
				<div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
					<div className="grid grid-cols-1 gap-20  lg:grid-cols-2">
						<div className="relative z-10 mb-14 lg:py-16">
							<div className="relative h-64 sm:h-80 lg:h-full">
								<div className="shadow-xl mb-3 lg:-mr-20">
									<video className="w-full" controls>
										<source src={MP4} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								</div>
								<div className="text-sm  leading-[120%] text-zinc-500 italic font-normal">
									{t.videoDescription}
								</div>
							</div>
						</div>

						<div className="relative lg:mt-0 mt-14  flex items-center lg:bg-zinc-100">
							<span
								className="hidden lg:absolute lg:inset-y-0  lg:-start-16 lg:block bg-zinc-100 lg:w-16"
							></span>

							<div className="p-0 md:p-20">
								<h2 className="text-2xl text-black font-bold sm:text-3xl">
									{t.whyChooseTitle}
								</h2>

								<p className="my-4 text-gray-600">
									{t.whyChooseText1}
								</p>

								<p className='my-4 mb-8 text-gray-600'>
									{t.whyChooseText2}
								</p>

								<Button type="button" disabled={false} onClick={() => navigate("/contact")} size="lg" variant="primary" hasborder={true}>
									{t.contactButton}
									<IoArrowForwardCircleSharp size="20" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* USP Section */}
			<div id="usp-pwas" className="max-w-screen-xl px-6 2xl:px-0 px-4 mx-auto py-20">

				<h2 className="text-2xl text-black font-bold sm:text-3xl">
					{t.uspTitle}
				</h2>
				<div className="grid grid-cols-1 mt-6 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{t.uspList.map((usp, index) => (
						<div
							key={index}
							className="block rounded-xl border border-zinc-200 lg:border-gray-100 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
						>

							<span className="inline-block rounded-lg p-5">
								{usp.icon}
							</span>
							<div className='px-5 pb-5 '>
								<h5 className="text-black  font-common w-full text-left my-2  text-[18px] font-semibold  ">
									{usp.title}
								</h5>
								<p className="text-lg text-zinc-600 font-normal">
									{usp.description}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* Contact Section */}
			<div id="contact-pwa" className="max-w-screen-xl px-6 2xl:px-0 px-4 mx-auto py-32 text-center">

				<h2 className="text-2xl text-black font-bold mb-6 sm:text-3xl">
					{t.contactTitle}
				</h2>
				<p className="text-[16px] lg:w-1/2 mx-auto lg:text-xl leading-[160%] text-gray-700 mb-6">
					{t.contactText}
				</p>
				<div className='lg:max-w-[250px] mx-auto'>
					<Button type="button" disabled={false} onClick={() => navigate("/contact")} size="lg" variant="secondary" hasborder={true}>
						{t.contactButton}
						<IoArrowForwardCircleSharp size="20" />
					</Button>
				</div>
			</div>
		</section>
	)
}

export default PWA;
