import React from 'react';
import TextBlock from '../components/text-block';
import TextReveal from '../components/v2/text-reveal/text-reveal';

const translations = {
	nl: {
		effectiveDate: "Ingangsdatum: 25-03-2024",
		privacyPolicy: "Privacybeleid",
		introText: "Bedankt dat u Walhallah.com heeft gekozen. Dit privacybeleid legt uit hoe we uw informatie verzamelen, gebruiken, openbaar maken en beveiligen wanneer u onze website Walhallah.com bezoekt, inclusief andere media, mediakanalen, mobiele websites of mobiele applicaties die ermee verbonden zijn (gezamenlijk de 'Site'). Lees dit privacybeleid zorgvuldig door. Als u niet akkoord gaat met de voorwaarden van dit privacybeleid, bezoek dan de Site niet.",
		collectionTitle: "Verzameling van uw informatie",
		collectionText: "We kunnen op verschillende manieren informatie over u verzamelen. De informatie die we op de Site kunnen verzamelen, omvat:",
		personalData: "- Persoonlijke gegevens: Persoonlijk identificeerbare informatie, zoals uw naam, e-mailadres en telefoonnummer, die u vrijwillig aan ons verstrekt wanneer u contact met ons opneemt via de Site of met ons communiceert via e-mail.",
		usageData: "- Gebruiksgegevens: Informatie over uw computerhardware en -software die automatisch door onze systemen wordt verzameld, zoals uw IP-adres, browsertype, domeinnamen, toegangstijden en verwijzende websiteadressen.",
		useTitle: "Gebruik van uw informatie",
		useText: "We kunnen de informatie die we van u verzamelen gebruiken om:",
		usePoints: [
			"Onze Site te onderhouden en te verbeteren;",
			"Te reageren op uw vragen en verzoeken;",
			"Onze producten en diensten te verbeteren;",
			"U marketingcommunicatie te sturen;",
			"Onderzoek en analyse uit te voeren;",
			"Te beschermen tegen juridische aansprakelijkheid."
		],
		disclosureTitle: "Openbaarmaking van uw informatie",
		disclosureText: "We kunnen uw informatie delen met derden op de manieren die in dit privacybeleid worden beschreven.",
		disclosurePoints: [
			"Gelieerde ondernemingen: We kunnen uw informatie delen met onze gelieerde ondernemingen om u gerelateerde producten en diensten aan te bieden.",
			"Dienstverleners: We kunnen uw informatie delen met derden die diensten namens ons verlenen.",
			"Zakenpartners: We kunnen uw informatie delen met onze zakenpartners om u bepaalde producten, diensten of promoties aan te bieden.",
			"Wettelijke vereisten: We kunnen uw informatie bekendmaken in reactie op dagvaardingen, gerechtelijke bevelen of andere wettelijke vereisten."
		],
		securityTitle: "Beveiliging van uw informatie",
		securityText: "We gebruiken administratieve, technische en fysieke beveiligingsmaatregelen om uw persoonlijke informatie te helpen beschermen. Hoewel we redelijke stappen hebben ondernomen om de persoonlijke informatie die u ons verstrekt te beveiligen, moet u zich ervan bewust zijn dat geen enkele beveiligingsmaatregel perfect of ondoordringbaar is.",
		changesTitle: "Wijzigingen in dit privacybeleid",
		changesText: "We behouden ons het recht voor om te allen tijde en om welke reden dan ook wijzigingen aan te brengen in dit privacybeleid. We zullen u op de hoogte stellen van eventuele wijzigingen door de 'Ingangsdatum' van dit privacybeleid bij te werken. U wordt aangemoedigd om dit privacybeleid periodiek te herzien om op de hoogte te blijven van updates.",
		contactTitle: "Neem contact met ons op",
		contactText: "Als u vragen of opmerkingen heeft over dit privacybeleid, neem dan contact met ons op via:",
		contactInfo: "Walhallah.com / Contact@walhallah.com"
	},
	en: {
		effectiveDate: "Effective Date: 25-03-2024",
		privacyPolicy: "Privacy Policy",
		introText: "Thank you for choosing Walhallah.com. This privacy policy explains how we collect, use, disclose, and secure your information when you visit our website Walhallah.com, including other media, media channels, mobile websites, or mobile applications connected to it (collectively, the 'Site'). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not visit the Site.",
		collectionTitle: "Collection of your information",
		collectionText: "We may collect information about you in a variety of ways. The information we may collect on the Site includes:",
		personalData: "- Personal Data: Personally identifiable information, such as your name, email address, and phone number, that you voluntarily provide to us when you contact us through the Site or communicate with us via email.",
		usageData: "- Usage Data: Information about your computer hardware and software that is automatically collected by our systems, such as your IP address, browser type, domain names, access times, and referring website addresses.",
		useTitle: "Use of your information",
		useText: "We may use the information we collect from you to:",
		usePoints: [
			"Maintain and improve our Site;",
			"Respond to your questions and requests;",
			"Improve our products and services;",
			"Send you marketing communications;",
			"Conduct research and analysis;",
			"Protect against legal liability."
		],
		disclosureTitle: "Disclosure of your information",
		disclosureText: "We may share your information with third parties in the ways described in this privacy policy.",
		disclosurePoints: [
			"Affiliates: We may share your information with our affiliates to offer you related products and services.",
			"Service Providers: We may share your information with third parties that perform services on our behalf.",
			"Business Partners: We may share your information with our business partners to offer you certain products, services, or promotions.",
			"Legal Requirements: We may disclose your information in response to subpoenas, court orders, or other legal requirements."
		],
		securityTitle: "Security of your information",
		securityText: "We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no security measures are perfect or impenetrable.",
		changesTitle: "Changes to this privacy policy",
		changesText: "We reserve the right to make changes to this privacy policy at any time and for any reason. We will notify you of any changes by updating the 'Effective Date' of this privacy policy. You are encouraged to review this privacy policy periodically to stay informed of updates.",
		contactTitle: "Contact us",
		contactText: "If you have questions or comments about this privacy policy, please contact us at:",
		contactInfo: "Walhallah.com / Contact@walhallah.com"
	}
};

const Privacy = ({ locale }) => {
	const t = translations[locale];

	return (
		<section id="page-privacy">
			<div id="hero__privacy" className="isolate max-w-screen-xl 2xl:px-0 px-4 mx-auto flex flex-col justify-center">
				<div className="2xl:w-2/3 w-full">
					<TextReveal>
						<span className="text-[35px] lg:text-[45px] pb-3  text-black font-semibold xl:text-[60px] poppins 2xl:text-[70px]">{t.privacyPolicy}</span>
					</TextReveal>
				</div>
			</div>
			<div className="font- container rounded-b-xl max-w-screen-xl 2xl:px-0 px-4 mx-auto text-black text-xl font-light mx-auto px-10 scale-[1]">
				<div className="font-normal rounded-lg">
					<div className="font-normal text-black">{t.introTwext}</div>
					<h2 className="font-normal text-xl font-semibold mt-8">{t.collectionTitle}</h2>
					<div className="font-normal text-black">{t.collectionText}</div>
					<div className="font-normal font-light ml-8 mb-8">
						<div className="mb-2">{t.personalData}</div>
						<div className="mb-2">{t.usageData}</div>
					</div>
					<h2 className="font-normal text-xl font-semibold mb-4">{t.useTitle}</h2>
					<div className="font-normal mb-8">{t.useText}</div>
					<div className="font-normal font-light ml-8 mb-8">
						{t.usePoints.map((point, index) => (
							<div className="mb-2" key={index}>{point}</div>
						))}
					</div>
					<h2 className="font-normal text-xl font-semibold mb-4">{t.disclosureTitle}</h2>
					<div className="font-normal mb-8">{t.disclosureText}</div>
					<div className="font-normal font-light ml-8 mb-8">
						{t.disclosurePoints.map((point, index) => (
							<div className="mb-2" key={index}>{point}</div>
						))}
					</div>
					<h2 className="font-normal text-xl font-semibold mb-4">{t.securityTitle}</h2>
					<div className="font-normal mb-8">{t.securityText}</div>
					<h2 className="font-normal text-xl font-semibold mb-4">{t.changesTitle}</h2>
					<div className="font-normal mb-8">{t.changesText}</div>
					<h2 className="font-normal text-xl font-semibold mb-4">{t.contactTitle}</h2>
					<div className="font-normal mb-8">{t.contactText}</div>
					<div className="font-normal mb-8">{t.contactInfo}</div>
				</div>
			</div>
		</section>
	);
};

export default Privacy;
