import { createClient } from '@supabase/supabase-js'
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON
export const supabase = createClient(supabaseUrl, supabaseKey);

/**
 * Registers an attendee by inserting their information into the 'Attendees' table.
 * @param {Object} attendee - The attendee object containing their information.
 * @returns {Object} - An object indicating the success of the registration and a message.
 */
export const registerAttendee = async (attendee, locale) => {
	const { error } = await supabase.from('Attendees').insert(attendee);
	if (error) {
		if (error.code === "23505") {
			return { isSuccess: false, message: locale === "en" ? "This organisation is already registered." : "Deze organisatie is al geregistreerd." };
		}
		return { isSuccess: false, message: error.message };
	}
	return { isSuccess: true, message: locale === "en" ? "Your organisation has been successfully registered. We will get back to you soon." : "Uw organisatie is succesvol geregistreerd. We nemen binnenkort contact met u op." };

}