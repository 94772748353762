import React from 'react';
import Button from '../components/button';
import TextReveal from '../components/v2/text-reveal/text-reveal';
import { convertTextToSlug } from '../tools/cn';
import { useNavigate } from 'react-router-dom';
import { CgChevronRight } from 'react-icons/cg';

export const translations = {
	nl: {
		workTitle: "Werk",
		recentProjects: "Recente Projecten",
		workDescription: "We hebben gewerkt aan een breed scala aan projecten, van kleine websites tot complexe webapplicaties. Wij zijn toegewijd aan het leveren van hoogwaardig werk op tijd en binnen budget. Enkele van onze laatste projecten",
		projects: [
			{
				title: "Ommetje met Tom",
				subtitle: "Alle verhalen en wandelroutes in je broekzak.",
				released: "September 2024",
				description: `Ommetje met Tom brengt routes en weetjes uit "Kijk omhoog, Amsterdam!" tot leven met interactieve kaarten, video's en foto's. De app biedt intuïtieve navigatie, offline gebruik en snelle installatie als PWA.'`,
				content: [
					`"Ommetje met Tom" is geïnspireerd op het boek "Kijk omhoog, Amsterdam!", dat gebruikers uitnodigt om de verborgen architectonische details van de stad te ontdekken. Het boek biedt rijke verhalen en weetjes die in de app tot leven komen, waardoor elke wandeling een leerzame en visueel boeiende ervaring wordt.`,
					`De app biedt interactieve routekaarten, waarbij video's, foto's en interessante stops de wandelroutes tot leven brengen. Gebruikers kunnen intuïtief door de routes navigeren, waarbij verhalen worden gegroepeerd op kleur en een moeilijkheidssysteem met voetstap-symbolen hen helpt bij het kiezen van geschikte routes.`,
					`Gebruikers kunnen zich registreren, hun profiel aanpassen, en profiteren van extra functies zoals een uitleg over hoe ze de app als Progressive Web App (PWA) kunnen installeren. "Ommetje met Tom" is geoptimaliseerd voor snelle prestaties, offline toegankelijkheid, en biedt een fullscreen ervaring zonder dat een aparte download nodig is.`
				],
				images: [
					"https://walhallah.com/images/ommetje/map-desktop.png",
					"https://walhallah.com/images/ommetje/map-details-desktop.png",
					"https://walhallah.com/images/ommetje/map-cluster-desktop.png",
					"https://walhallah.com/images/ommetje/map-mobile.png",
					"https://walhallah.com/images/ommetje/details-mobile.png",
					"https://walhallah.com/images/ommetje/login-mobile.png",
				],
				button: "Bekijk",
				link: "https://kaart.ommetjemettom.nl",
				image: 'https://walhallah.com/images/ommetje/ommetje-met-tom.png',
				tags: ["Interactieve Kaart", "Progressive Web App", "Wandelroutes", "Stadsverkenning"],
				bg: 'bg-[#fdeac994]'
			},
			//1014 x 1820 
			{
				title: "Zeppay",
				subtitle: "Het eenvoudigste facturatieplatform",
				description: "Zeppay is een alles-in-één online platform voor kleine bedrijven en zelfstandigen in de dienstensector. Het stelt gebruikers in staat om moeiteloos projecten te creëren, te plannen, offertes en facturen te genereren, betalingen te initiëren en klanten toegang te geven tot een gepersonaliseerd portaal. Met functies zoals projectbeheer, offerte- en factuurbeheer, klantinteractie en geïntegreerde agenda's, biedt Zeppay.co een gebruiksvriendelijke en efficiënte online oplossing voor het beheren van allerlei soorten diensten.",
				button: "Bekijk",
				link: "https://zeppay.co",
				image: 'https://bafybeiavnabn43pmf3mskqr5jzwrgwd7adt26aspuhccxkfh5j6js5nhxa.ipfs.w3s.link/zep.png',
				tags: ["SaaS", "Facturering", "Projectbeheer", "Klantenportaal", "Betalingsintegratie"],
			},
			{
				title: "Heyo",
				subtitle: "Een nieuwe manier om contact te maken",
				description: "Ontdek een nieuwe manier om verbinding te maken. Vereenvoudig persoonlijke en zakelijke connecties. Deel essentiële details, van e-mails tot LinkedIn-profielen, via QR-codes. Sla deze gegevens moeiteloos op in de contacten van uw telefoon, zonder handmatige invoer.",
				button: "Bekijk",
				link: "https://heyo.one",
				image: 'https://bafybeic4didt5flnvgcahz7vyvqurvp7mgf2lldmyrjp37mtkw2spqk4ny.ipfs.w3s.link/hey.png',
				tags: ["Sociaal Netwerken", "QR-codes", "PWA", "Gebruikerservaring", "Zakelijke Netwerken"],
			},

			{
				title: "La Florentina",
				subtitle: "Traditionele Argentijnse riemen",
				description: "100% traditionele Argentijnse riemen. LaFlorentina viert de Argentijnse cultuur en ambacht. We hebben een webshop gemaakt die hun unieke, handgemaakte riemen presenteert. Het draait allemaal om het brengen van een vleugje Zuid-Amerikaanse stijl naar de digitale wereld.",
				button: "Bekijk",
				link: "https://laflorentina.nl/",
				image: 'https://bafybeibuu2js3xhhpq6w6t2c4nsmkqvv4mlvp6ls3hv77rr2kjzin5tsu4.ipfs.w3s.link/la.png',
				tags: ["E-commerce", "Handgemaakte Producten", "Argentijnse Cultuur", "Mode", "Webshop"],
			},
			{
				title: "The Shining Star",
				subtitle: "Help sloppenwijkkinderen schitteren",
				description: "Help sloppenwijkkinderen schitteren in het leven. Shining Star Africa helpt kinderen in de sloppenwijken van Nairobi een voorsprong te krijgen met onderwijs. We hebben een website gebouwd die hun geweldige verhaal vertelt. Het gaat allemaal om het maken van een verschil.",
				button: "Bekijk",
				link: "https://www.shiningstar-africa.com/",
				image: 'https://bafybeiazzufzxlkqgbuzzgbwkdkyqvgek5egolfde2gvuy4hqejrdoqsui.ipfs.w3s.link/star.png',
				tags: ["Liefdadigheid", "Onderwijs", "Non-Profit", "Kenia", "Sociale Impact"],
			},
		],
	},
	en: {
		workTitle: "Work",
		recentProjects: "Recent Projects",
		workDescription: "We have worked on a wide range of projects, from small websites to complex web applications. We are committed to delivering high-quality work on time and within budget. Some of our latest projects",
		projects: [
			{
				"title": "Walk with Tom",
				"subtitle": "All stories and walking routes in your pocket.",
				"released": "September 2024",
				"description": `"Walk with Tom" brings routes and facts from "Look Up, Amsterdam!" to life with interactive maps, videos, and photos. The app offers intuitive navigation, offline use, and fast installation as a PWA.`,
				"content": [
					`"Walk with Tom" is inspired by the book "Look Up, Amsterdam!", which invites users to discover the hidden architectural details of the city. The book offers rich stories and facts that come to life in the app, making every walk an educational and visually engaging experience.`,
					`The app offers interactive route maps, where videos, photos, and interesting stops bring the walking routes to life. Users can intuitively navigate through the routes, with stories grouped by color and a difficulty system using footprint symbols to help them choose suitable routes.`,
					`Users can register, customize their profiles, and benefit from additional features like an explanation of how to install the app as a Progressive Web App (PWA). "Walk with Tom" is optimized for fast performance, offline accessibility, and provides a fullscreen experience without requiring a separate download.`
				],
				"images": [
					"https://walhallah.com/images/ommetje/map-desktop.png",
					"https://walhallah.com/images/ommetje/map-details-desktop.png",
					"https://walhallah.com/images/ommetje/map-cluster-desktop.png",
					"https://walhallah.com/images/ommetje/map-mobile.png",
					"https://walhallah.com/images/ommetje/details-mobile.png",
					"https://walhallah.com/images/ommetje/login-mobile.png",

				],
				"button": "View",
				"link": "https://kaart.ommetjemettom.nl",
				"image": "https://walhallah.com/images/ommetje/ommetje-met-tom.png",
				"tags": ["Interactive Map", "Progressive Web App", "Walking Routes", "City Exploration"],
				"bg": "bg-[#fff]"
			}
			,
			{
				title: "Zeppay",
				subtitle: "The simplest invoicing platform",
				description: "Zeppay is an all-in-one online platform for small businesses and freelancers in the service sector. It enables users to effortlessly create, plan projects, generate quotes and invoices, initiate payments, and give clients access to a personalized portal. With features like project management, quote and invoice management, client interaction, and integrated calendars, Zeppay.co offers a user-friendly and efficient online solution for managing all types of services.",
				button: "View",
				link: "https://zeppay.co",
				image: 'https://bafybeiavnabn43pmf3mskqr5jzwrgwd7adt26aspuhccxkfh5j6js5nhxa.ipfs.w3s.link/zep.png',
				tags: ["SaaS", "Invoicing", "Project Management", "Client Portal", "Payment Integration"],

			},
			{
				title: "Heyo",
				subtitle: "A new way to connect",
				description: "Discover a new way to connect. Simplify personal and business connections. Share essential details, from emails to LinkedIn profiles, via QR codes. Effortlessly save these details to your phone contacts without manual entry.",
				button: "View",
				link: "https://heyo.one",
				image: 'https://bafybeic4didt5flnvgcahz7vyvqurvp7mgf2lldmyrjp37mtkw2spqk4ny.ipfs.w3s.link/hey.png',
				tags: ["Social Networking", "QR Codes", "PWA", "User Experience", "Business Networking"],
			},

			{
				title: "La Florentina",
				subtitle: "Traditional Argentine belts",
				description: "100% traditional Argentine belts. LaFlorentina celebrates Argentine culture and craftsmanship. We created a webshop that showcases their unique, handmade belts. It's all about bringing a touch of South American style to the digital world.",
				button: "View",
				link: "https://laflorentina.nl/",
				image: 'https://bafybeibuu2js3xhhpq6w6t2c4nsmkqvv4mlvp6ls3hv77rr2kjzin5tsu4.ipfs.w3s.link/la.png',
				tags: ["E-commerce", "Handcrafted Products", "Argentine Culture", "Fashion", "Webshop"],

			},
			{
				title: "The Shining Star",
				subtitle: "Help slum children shine",
				description: "Help slum children shine in life. Shining Star Africa helps children in the slums of Nairobi get a head start with education. We built a website that tells their amazing story. It's all about making a difference.",
				button: "View",
				link: "https://www.shiningstar-africa.com/",
				image: 'https://bafybeiazzufzxlkqgbuzzgbwkdkyqvgek5egolfde2gvuy4hqejrdoqsui.ipfs.w3s.link/star.png',
				tags: ["Charity", "Education", "Non-Profit", "Kenya", "Social Impact"],
			},
		],
	}
};


const Cases = ({ locale }) => {
	const navigate = useNavigate();
	const t = translations[locale];
	if (!t) {
		return <div>Translation not available</div>;
	}

	return (
		<section id="page-cases">
			<div id="hero__cases" className="max-w-screen-xl px-6 2xl:px-0 px-4  mx-auto ">
				<div className="w-full flex mb-20  text-left flex-col ">
					<h2 className="text-2xl sm:text-4xl   text-black  font-bold mibold pb-3">{t.recentProjects}</h2>
					<span className="font-inter  text-lg sm:text-2xl lg:w-2/3 ">{t.workDescription}</span>
				</div>

			</div>
			<div className='max-w-screen-xl h-full px-6 2xl:px-0 px-4  min-h-screen mt-10  xl:mx-auto   '>
				<ul className="relative z-72 grid md:grid-cols-2 grid-cols-1 items-start 2xl:gap-2 lg:gap-5 gap-1  my-38 ">
					{t.projects.map((project, index) => (
						<div onClick={() => navigate(`/projects/${convertTextToSlug(project.title)}`, { state: { project } })} className='relative group flex overflow-hidden flex-col z-[9999]  gap-4'>
							<img loading="lazy" src={project.image} alt={project.title} className='w-full border h-full aspect-video lg:hidden object-cover object-top rounded-lg' />
							<img loading="lazy" src={project.image} alt={project.title} className='w-full border h-full hidden lg:flex aspect-video object-cover object-top rounded-lg' />

							<div className='flex w-full justify-between md:flex-row flex-col gap-3 translate-y-full absolute bottom-0 cursor-pointer bg-white/[0.6] p-6 hover:bg-white/[0.8] backdrop-blur group-hover:translate-y-0 transition'>
								<li key={index} className='flex  w-full flex-col md:items-start  gap-3  flex lg:mb-0'>
									<h2 className='text-lg lg:text-xl font-semibold font-inter xl:text-2xl   text-black  2xl:text-3xl w-full justify-between  flex items-center'>{project.title} <CgChevronRight /></h2>
									<div className=" flex flex-row  gap-0 border-none w-full  rounded-lg   shadow-none font-bold">
										{project.tags.map((tag, index) => (
											<div key={index} className=" text-black p-1 text-black px-2  text-xs lg:text-sm rounded ">
												{tag}
											</div>
										))}
									</div>
								</li>

							</div>
						</div>
					))}
				</ul>
			</div >
		</section >
	);
}

export default Cases;
