import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { translations } from '../pages/cases';
import AOS from 'aos';

const RecentProjects = ({ locale }) => {
	const isMobile = window.innerWidth < 1024;
	const projects = translations[locale].projects;
	const swiperRef = useRef(null);

	useEffect(() => {
		AOS.init({
			duration: 500,
			once: true,
		});
	}, []);

	return (
		<Swiper
			className='absolute flex-row items-center h-[25em] lg:h-[30em] 2xl:h-[35em] left-0 w-full'
			spaceBetween={"10px"}
			slidesPerView={isMobile ? 1.1 : 2.2}
			onSlideChange={() => console.log('slide change')}
			onSwiper={(swiper) => console.log(swiper)}
			initialSlide={0}
			ref={swiperRef}>
			{projects.map((project, idx) => (
				<SwiperSlide key={idx} className="relative h-fit">
					<a data-aos="fade-left" data-aos-delay={`${idx + idx}00`} href={project.link} target='_blank' rel='noreferrer' className="absolute rounded border inset-0">
						<article class=" overflow-hidden  transition cursor-pointer">
							<img
								alt=""
								src={project.image}
								class="  w-full h-[400px] aspect-video rounded object-cover" />
							<div class="absolute top-[390px]  h-fit  rounded-b w-full bg-black ">
								<div class="py-4 px-4 flex flex-col font-common  sm:p-9">
									<h6 class="text-xl lg:text-2xl  font-bold text-white">{project.title}</h6>
									<span class="text-[16px] lg:text-xl  font-norma text-white">{project.subtitle}</span>
								</div>
							</div>
						</article>
					</a>
				</SwiperSlide>
			))}
			{/* <div className="swiper-pagination text-2xl  flex flex-row justify-between items-center py-10 ">
				<div className="text-zinc-400 hover:text-black hover:border-black border-zinc-500/30 border    cursor-pointer transition-all w-fit rounded-full p-3 lg:p-4 bg-white backdrop-blur">
					<IoChevronBackSharp className="text-zinc-700" />
				</div>
				<div className="text-zinc-400 hover:text-black hover:border-black border-zinc-500/30 border    cursor-pointer transition-all w-fit rounded-full p-3 lg:p-4 bg-white backdrop-blur">
					<IoChevronForwardSharp className="text-zinc-700" />
				</div>
			</div> */}
		</Swiper>
	)
}

export default RecentProjects;