import React from 'react';

const Modal = ({ isModalOpen, setIsModalOpen, content }) => {

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	if (isModalOpen)
		return (
			<div className="fixed top-0 left-0 inset-0 z-50 flex items-center justify-start">
				<div className="rounded-[50px] absolute inset-0" onClick={handleCloseModal}></div>
				<div ddata-aos="fade-up" className="bg-white p-6 mx-auto border-2 border-neutral-300 rounded-full shadow-xl z-50">
					<p className='flex font-medium text-lg flex-row items-center gap-3 justify-start'>{content}</p>
				</div>
			</div>

		);
};

export default Modal;
